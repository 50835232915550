import moment from "moment";
import React from "react";
import { IoMdCamera } from "react-icons/io";

function ImageUploader({ title, images, setImages }) {
  const onChange = (event) => {
    const { files } = event.target;
    let imagesFile = [];
    if (images) imagesFile = [...images];
    Object.entries(files).forEach(([key, value]) => imagesFile.push(value));
    setImages([...imagesFile]);
  };
  const deleteImage = (name) => {
    if (images.length === 0) return;
    let allImages = [...images];
    allImages = allImages.filter((item) => item.name !== name);
    setImages([...allImages]);
  };

  return (
    <div className="App relative w-full rounded bg-slate-200 dark:bg-slate-900 p-4">
      <input className="hidden" id={title} type="file" accept="image/*" onChange={(e) => onChange(e)} />
      <h4 className=" text-lg">{title}</h4>
      <div className="w-full flex justify-between items-center">
        <label
          className="bg-blue-500 my-2 flex text-base items-center gap-1 hover:bg-blue-600 transition-all text-white px-2 py-1  rounded"
          htmlFor={title}
        >
          <IoMdCamera />
          Upload new Images
        </label>
        {images.length !== 0 && (
          <button
            onClick={() => setImages(null)}
            className="bg-red-500 hover:bg-red-600 transition-all ease-in cursor-pointer rounded px-2 py-1 text-white"
          >
            Delete All
          </button>
        )}
      </div>

      <table className="border-collapse my-2 w-full border-spacing-y-0 table-auto ">
        <thead>
          {images ? (
            images.length > 0 ? (
              <tr className="">
                <th className="text-left">Image File</th>
                <th className="text-center">Date Uploaded</th>
                <th className=""></th>
              </tr>
            ) : null
          ) : null}
        </thead>
        <tbody>
          {images &&
            images.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <img src={URL.createObjectURL(item)} alt="" className="w-24 h-24 rounded object-cover" />
                  </td>
                  <td className="text-center p-4">{moment(item?.lastModifiedDate).format("MMMM Do YYYY, h:mm:ss a")}</td>
                  <td className="text-right p-4">
                    <button onClick={() => deleteImage(item?.name)} className="bg-red-500 text-right p-2 text-white rounded">
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default ImageUploader;
