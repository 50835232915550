import React, { useContext, useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { contextApi } from "../Context/contextApi";
import { toast } from "react-toastify";
import { Input, CheckoutWizard, SelectBox, ImageUploader, FormNav } from "./";
import { useNavigate } from "react-router-dom";
import useTrcukList from "../Hooks/useTruckList";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import useLoader from "../Hooks/useLoader";
import { FaArrowRight } from "react-icons/fa";
import RegesterConfigApi from "../Config/RegisterApiConfig";

function TrucksInfo() {
  const { data, isLoading } = useTrcukList();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [regesteration, setRegesteration] = useState([]);
  const [insurance, setInsurance] = useState([]);
  const [truck, setTruck] = useState([]);
  const { state } = useContext(contextApi);
  const [disable, setDisable] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [saveImage, setSaveImage] = useState({});
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const { setLoader, removeLoader } = useLoader();

  const inputes = [
    {
      id: "truck-type",
      label: "Truck Type",
      type: "selectBox",
      value: "",
      values: !isLoading ? data : null,
      register: { ...register("truck_type", { required: true }) },
      error: errors.truck_type ? "This Field is Required" : null,
    },
    {
      id: "owner",
      label: "Ownership",
      type: "selectBox",
      value: "",
      values: [
        { title: "owner", id: "owner" },
        { title: "leasing", id: "leasing" },
        { title: "rent", id: "rent" },
      ],
      register: { ...register("ownership", { required: true }) },
      error: errors?.ownership ? "This Field is Required" : null,
    },
    {
      id: "vin_number",
      label: "vin Number",
      type: "text",
      register: { ...register("vin_number", { required: "This Field is required" }) },
      error: errors?.vin_number ? { status: true, message: errors?.vin_number?.message } : null,
    },

    {
      id: "year_vehicle_manufacture",
      label: "Year Manufacture",
      type: "text",
      register: { ...register("year_vehicle_manufacture", { required: "This Field is required" }) },
      error: errors?.year_vehicle_manufacture ? { status: true, message: errors?.year_vehicle_manufacture?.message } : null,
    },

    {
      id: "mileage",
      label: "Mileage",
      type: "text",
      register: { ...register("mileage", { required: "This Field is required" }) },
      error: errors?.mileage ? { status: true, message: errors?.mileage?.message } : null,
    },
    {
      id: "max Carriable Weight",
      label: "max Carriable Weight",
      type: "text",
      register: { ...register("max_carriable_weight", { required: "This Field is required" }) },
      error: errors?.max_carriable_weight ? { status: true, message: errors?.max_carriable_weight?.message } : null,
    },
    {
      id: "max_pallets",
      label: "Max Pallets",
      type: "text",
      register: { ...register("max_pallets", { required: "This Field is required" }) },
      error: errors?.max_pallets ? { status: true, message: errors?.max_pallets?.message } : null,
    },
    {
      id: "vehicle_length",
      label: "Vehicle Length",
      type: "text",
      register: { ...register("vehicle_length", { required: "This Field is required" }) },
      error: errors?.vehicle_length ? { status: true, message: errors?.vehicle_length?.message } : null,
    },
    {
      id: "wheel_width",
      label: "Wheel Width",
      type: "text",
      register: { ...register("wheel_width", { required: "This Field is required" }) },
      error: errors?.wheel_width ? { status: true, message: errors?.wheel_width?.message } : null,
    },
    {
      id: "vehicle_height",
      label: "Vehicle Height",
      type: "text",
      register: { ...register("vehicle_height", { required: "This Field is required" }) },
      error: errors?.vehicle_height ? { status: true, message: errors?.vehicle_height?.message } : null,
    },
    {
      id: "backdoor_width",
      label: "Backdoor Width",
      type: "text",
      register: { ...register("backdoor_width", { required: "This Field is required" }) },
      error: errors?.backdoor_width ? { status: true, message: errors?.backdoor_width?.message } : null,
    },
    {
      id: "backdoor_height",
      label: "Backdoor Height",
      type: "text",
      register: { ...register("backdoor_height", { required: "This Field is required" }) },
      error: errors?.backdoor_height ? { status: true, message: errors?.backdoor_height?.message } : null,
    },
    {
      id: "sidedoor_width",
      label: "Sidedoor Width",
      type: "text",
      register: { ...register("sidedoor_width", { required: "This Field is required" }) },
      error: errors?.sidedoor_width ? { status: true, message: errors?.sidedoor_width?.message } : null,
    },
    {
      id: "sidedoor_height",
      label: "Sidedoor Height",
      type: "text",
      register: { ...register("sidedoor_height", { required: "This Field is required" }) },
      error: errors?.sidedoor_height ? { status: true, message: errors?.sidedoor_height?.message } : null,
    },
  ];
  const ImagesField = [
    {
      MaxImages: 5,
      images: truck,
      setImages: setTruck,
      title: "Trucks Images",
    },
    {
      MaxImages: 5,
      images: insurance,
      setImages: setInsurance,
      title: "Insurance Image",
    },
    {
      MaxImages: 5,
      images: regesteration,
      setImages: setRegesteration,
      title: "Regesteration Images",
    },
  ];
  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    insurance?.forEach((item) => formData.append("insurance_image[]", item));
    truck?.forEach((item) => formData.append("truck_images[]", item));
    regesteration?.forEach((item) => formData.append("registration_image[]", item));
    setSaveData({ ...saveData, ...data });
    setSaveImage({ ...saveImage, truck, insurance, regesteration });
    if (regesteration?.length === 0 && insurance?.length === 0 && truck?.length === 0) return setError(true);
    setError(false);
    setLoader();
    try {
      const { token } = state;

      const res = await RegesterConfigApi.post("/api/v1/register/truck-info-store", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      const { status, data } = res;

      if (status === 200) {
        removeLoader();
        Cookies.set("truck", JSON.stringify(saveData));
        Cookies.set("ImagesTruck", JSON.stringify(saveImage));
        const { message } = data;
        toast.success(message);

        navigate("/form/licence");
      }
    } catch (error) {
      removeLoader();
      console.log(error);
      toast.error(error?.message);
    }
  };

  let truck_info = useMemo(() => (Cookies.get("truck") ? JSON.parse(Cookies.get("truck")) : null), []);
  // let images = Cookies.get("ImagesTruck") ? JSON.parse(Cookies.get("ImagesTruck")) : null;
  useEffect(() => {
    if (truck_info) {
      Object.entries(truck_info).forEach(([key, value]) => {
        setValue(key, value);
      });
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [setValue, truck_info]);

  return (
    <>
      <FormNav link={"/form"} title={"Trucks Information"} />
      <CheckoutWizard activeStep={2} />
      <form onSubmit={handleSubmit(onSubmit)} className="flex w-[95%] relative md:w-4/5 mx-auto flex-col gap-3">
        {/* <h3 className="text-lg md:text-xl font-semibold  text-amber-400">Trucks informaion</h3> */}
        <div className="grid grid-cols-2 gap-3  ">
          {inputes.map((input) => {
            if (input.type === "selectBox")
              return (
                <span className="col-span-2 sm:col-span-1" key={input.id}>
                  <SelectBox disable={disable} {...input} regester={input.register} />
                  {input.error && (
                    <Alert variant="filled" className="my-2" severity="error">
                      {input?.error}
                    </Alert>
                  )}
                </span>
              );
            return (
              <span className="col-span-2 sm:col-span-1" key={input.id}>
                <Input modal={disable} key={input.id} {...input} regester={input.register} />
              </span>
            );
          })}
        </div>

        {ImagesField.map((item) => (
          <ImageUploader {...item} key={item.title} />
        ))}
        {error && (
          <Alert variant="filled" className="my-2" severity="error">
            Truck,Insurance and Regesteration image should be uploaded
          </Alert>
        )}
        <button className="self-start mt-4 flex items-center gap-2  px-4 py-2 rounded-md text-white bg-blue-500" type="submit">
          Next <FaArrowRight className="inline" />
        </button>
      </form>
    </>
  );
}

export default TrucksInfo;
