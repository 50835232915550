import React from "react";
import moment from "moment";
import { FaPhone } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function LoadData({ data }) {
  const { id, load_date, load_miles, load_pick_up, load_deliver_to, user_phone, user_truck_size, status } = data;
  const navigate = useNavigate();
  const navigateHandler = () => navigate(`/load/${id}`);
  const statusStyle = status === "accept" ? "bg-green-500" : status === "reject" ? "bg-red-500" : "bg-amber-500";
  return (
    <div
      onClick={navigateHandler}
      className="col-span-1 p-2 hover:shadow hover:shadow-gray-600 duration-150
       transition-all ease-linear dark:hover:shadow-gray-300 cursor-pointer overflow-x-hidden   rounded-md  bg-slate-300 dark:bg-slate-950"
    >
      <div className="w-full flex justify-between py-2  items-center border-b dark:border-gray-400 border-gray-900">
        <h3 className={`capitalize px-2 py-1  text-slate-50 rounded-full ${statusStyle}`}>{status}</h3>
        <h3 className=" font-light text-xs text-center">
          deadhead <span className="  font-medium text-base">{load_miles} mi</span>
        </h3>
      </div>
      <div className="flex flex-col border-b dark:border-gray-400 border-gray-900 py-2">
        <h3 className="text-base ">
          Pick up <span className="text-xs ">{moment(load_date).format("MMM Do dddd h:mm a")}</span>
        </h3>
        <h3 className="text-xs ">{load_pick_up}</h3>
        <h3 className="text-base ">Drop off</h3>
        <h3 className="text-xs ">{load_deliver_to}</h3>
        <div className="flex items-baseline gap-1">
          <span className="text-base">Truck Type</span>
          <h3 className="text-xs">{user_truck_size}</h3>
        </div>
      </div>
      <div className="flex justify-between items-center py-3 px-2">
        <h3 className="text-gray-800 dark:text-gray-400 font-light text-base ">Driver Phone</h3>
        <button
          onClick={() => (window.location.href = `tel:${user_phone}`)}
          className="text-sm font-medium text-black bg-blue-400 hover:bg-blue-500   dark:hover:bg-amber-500 hover:text-white transition-all ease-in dark:bg-amber-400 rounded-full p-2"
        >
          <FaPhone className="inline-block" /> +{user_phone}
        </button>
      </div>
    </div>
  );
}
