import useSwr from "swr";
import axios from "axios";

const baseURL = "https://atoz-loadal-location.metariom.shop";

const AxiosConfig = axios.create({
  baseURL,
  headers: {
    " x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
  },
});

const fetcher = (url) => AxiosConfig.get(url).then((res) => res.data);

const useLoadData = (params = null) => {
  const { data, error, isLoading, mutate } = useSwr(`/api/v1/admin/get-loads`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { loadData: data?.data, error, isLoading, mutate };
};

export default useLoadData;
