import Cookies from "js-cookie";
import React, { createContext, useReducer } from "react";
// Add , Remove,Set_Step ,Set_Data

const initState = {
  email: "",
  token: null,
};
function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "Set_Data":
      const { email, token } = payload;
      const atoz_cookies = { email, token };
      Cookies.set("atoz-coolies", JSON.stringify(atoz_cookies), { expires: 7 });
      return { ...state, email, token };
    case "UPDATE_STATE":
      const cookie = Cookies.get("atoz-coolies");

      if (cookie) {
        const { token, email } = JSON.parse(cookie);
        return { ...state, token, email };
      } else {
        return state;
      }
    case "LOG_OUT":
      Cookies.remove("atoz-coolies");
      Cookies.remove("info");
      Cookies.remove("liecence");
      Cookies.remove("truck");
      Cookies.remove("ImagesTruck");
      Cookies.remove("bank_info");
      return initState;

    default:
      return state;
  }
}

export const contextApi = createContext();

export default function ContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);

  const value = { state, dispatch };
  return <contextApi.Provider value={value}>{children}</contextApi.Provider>;
}
