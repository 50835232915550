import React, { useMemo, useEffect, useContext } from "react";
import useInfo from "./Hooks/useInfo";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

import { initFirebaseMessagingRegistration } from "./firebase";
import LocationProvider from "./Context/locationApi";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { contextApi } from "./Context/contextApi";
import { postMessageHandler } from "./Utils/NotificationService";
import Cookies from "js-cookie";

const Main = () => {
  const darkMode = useMemo(() => localStorage.getItem("theme") === "dark", []);
  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
  }, [darkMode]);
  const navigate = useNavigate();
  const { data } = useInfo();
  const { state } = useContext(contextApi);
  const { token } = state;

  useEffect(() => {
    if (data?.type !== "driver") return;

    if (data) {
      const { register_level, register_level_title } = data;
      if (register_level_title === "complete") return;

      switch (register_level) {
        case 1:
          navigate("/form");
          break;
        case 2:
          navigate("/form/truck");
          break;
        case 3:
          navigate("/form/licence");
          break;
        case 4:
          navigate("/form/bank");
          break;
        case 5:
          navigate("/form/phoneVerify");
          break;
        case 6:
          navigate("/form/contract");
          break;
        default:
          navigate("/home");
          break;
      }
    }
  }, [data, navigate]);

  useEffect(() => {
    if (data?.status_register === "pending") {
      toast.info(`Your Regerstration incompleted ${data?.register_level_title} is not resived`);
    }
  }, [data]);

  const beamsClient = new PusherPushNotifications.Client({
    instanceId: "715852e7-5aca-4d42-b4cc-df0d5146ad0c",
  });

  beamsClient
    .start()
    .then(() => beamsClient.addDeviceInterest("atoz-app"))
    .catch(console.error);
  // .then(() => console.log("Successfully registered and subscribed!"))

  // Check if the Wake Lock API is supported for wake screen all of time user use this app
  if ("wakeLock" in navigator) {
    // Request a screen wake lock
    navigator.wakeLock
      .request("screen")
      .then((wakeLockObj) => {
        // Wake lock has been acquired
        // console.log("Screen wake lock acquired");

        // You can release the wake lock when you no longer need it
        wakeLockObj
          .release()
          .then(() => {
            // console.log("Screen wake lock released");
          })
          .catch((err) => {
            // console.error("Failed to release screen wake lock:", err);
          });
      })
      .catch((err) => {
        // Failed to acquire wake lock
        console.error("Failed to acquire screen wake lock:", err);
      });
  } else {
    // Wake Lock API not supported
    console.warn("Wake Lock API is not supported");
  }
  // Ask user push notification permission
  function requestNotificationPermission() {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      return;
    }

    if (Notification.permission === "granted") {
      initFirebaseMessagingRegistration();
      if (data) {
        postMessageHandler({ token, user_id: [data?.id] });
      }
      console.log(Cookies.get("Firebase-Token"));
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          initFirebaseMessagingRegistration();
          if (data) {
            postMessageHandler({ token, user_id: [data?.id] });
          }
        }
      });
    }
  }
  requestNotificationPermission();

  return (
    <LocationProvider>
      <main className={"w-screen relative  h-fit min-h-[100vh]  bg-slate-100 dark:bg-slate-900 text-gray-950 dark:text-white"}>
        <Outlet />
      </main>
    </LocationProvider>
  );
};

export default Main;
