import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import Countdown from "react-countdown";
import { MuiOtpInput } from "mui-one-time-password-input";
import { IoClose } from "react-icons/io5";

function OTPModal({ code, setCode, isOpen, setIsOpen, title, submit, reSend, type }) {
  const { Panel, Title } = Dialog;
  const [timer, setTimer] = useState(true);
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setTimer(false);
      return null;
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <Dialog className={"top-[25%] absolute bg-slate-900 z-50  w-1/2 left-[25%]"} open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="fixed inset-0 z-0  bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 flex w-full items-center justify-center p-4 ">
        <Panel className="mx-auto  p-6 flex flex-col gap-3 rounded bg-slate-100 h-full  text-gray-950  w-full">
          <button onClick={() => setIsOpen(false)} className="text-2xl rounded-full z-30 border w-fit absolute right-10 border-red-600 text-red-600">
            <IoClose />
          </button>
          <Title>{title}</Title>
          <MuiOtpInput onComplete={submit} value={code} autoFocus onChange={setCode} length={5} />
          <div className="flex justify-between p-4">
            <button
              onClick={reSend}
              disabled={timer}
              className={`${
                timer ? "border-2 opacity-50 border-blue-600 text-blue-600" : " bg-blue-600   hover:bg-blue-800 text-white"
              }   px-4 transition-all ease-in flex rounded   py-2 gap-2`}
            >
              Resend
              {timer ? <Countdown date={Date.now() + 60000} renderer={renderer} /> : null}
            </button>
            <button onClick={() => submit()} className="px-2 py-1 text-white bg-blue-500 hover:bg-blue-600 transition-all ease-in rounded">
              Validate Code
            </button>
          </div>
        </Panel>
      </div>
    </Dialog>
  );
}

export default OTPModal;
