import React, { useState, useCallback, useContext, useMemo } from "react";
import { Dialog } from "@headlessui/react";

import { contextApi } from "../Context/contextApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axios from "axios";

const LogoutButton = () => {
  let [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch } = useContext(contextApi);
  const token = useMemo(() => (Cookies.get("atoz-coolies") ? JSON.parse(Cookies.get("atoz-coolies")).token : state?.token), [state]);

  const logoutHandler = useCallback(async () => {
    try {
      const res = await axios.post(
        "https://atoz-register.metariom.com/api/v1/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
          },
        }
      );

      const { data, status } = res;
      if (status === 200) {
        navigate("/");
        dispatch({ type: "LOG_OUT" });
        toast.info(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token, navigate, dispatch]);

  return (
    <>
      <button onClick={() => setIsOpen(true)} className="text-white bg-red-500 hover:bg-red-700 px-2 py-1 rounded">
        Logout
      </button>
      <LogoutModal isOpen={isOpen} action={logoutHandler} setIsOpen={setIsOpen} />
    </>
  );
};

export default LogoutButton;

function LogoutModal({ isOpen, setIsOpen, action }) {
  const { Description, Title, Panel } = Dialog;
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="top-[25%] absolute z-50    w-1/2 left-[25%]">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className="fixed  text-gray-950 dark:text-gray-50  inset-0 flex w-screen items-center justify-center ">
        <Panel className={" bg-slate-50 w-[80%] dark:bg-slate-700 p-4 rounded"}>
          <Title className={"mb-4 text-lg border-b dark:border-gray-50   py-2"}>Logout Account</Title>
          <Description>Are you sure to Deacvive account</Description>
          <div className="w-full flex my-4 justify-between items-center">
            <button className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-700 transition-all ease-in" onClick={() => action()}>
              Logout
            </button>
            <button className="px-4 py-2 rounded bg-green-500 text-white hover:bg-green-700 transition-all ease-in" onClick={() => setIsOpen(false)}>
              Cancel
            </button>
          </div>
        </Panel>
      </div>
    </Dialog>
  );
}
