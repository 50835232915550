import React from "react";

const SelectBox = ({ regester, values, disable }) => {
  return (
    <>
      <label htmlFor="underline_select" className="sr-only   bg-slate-200"></label>
      <select
        disabled={disable}
        {...regester}
        id="underline_select"
        className="block py-2.5 px-2 w-full text-sm text-gray-700 dark:text-gray-300 bg-transparent border-0 border-b-2 border-gray-200 appearance-none bg-slate-300 focus:outline-none focus:ring-0 focus:border-gray-200 dark:focus:bg-slate-950 focus:bg-slate-200 rounded peer"
      >
        {values &&
          values.map((item) => (
            <option key={item?.id} className="px-2 bg-slate-200" value={item?.id}>
              {item?.title}
            </option>
          ))}
      </select>
    </>
  );
};

export default SelectBox;
