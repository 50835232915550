import React from "react";

export default function TextAria() {
  return (
    <div className="px-4 py-2 my-2 bg-white rounded-lg dark:bg-gray-800">
      <label htmlFor="comment" className="sr-only">
        Your comment
      </label>
      <textarea
        id="comment"
        rows="4"
        cols="10"
        className="w-full px-0 text-sm resize-none text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
        placeholder="Write a description..."
      ></textarea>
    </div>
  );
}
