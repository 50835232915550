import React from "react";
import { Dialog } from "@headlessui/react";
import { IoClose } from "react-icons/io5";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import { FaTruck, FaBox } from "react-icons/fa6";

function FullMap({ isOpen, setIsOpen, marker1, marker2 }) {
  const { Panel } = Dialog;

  return (
    <Dialog className={"top-[25%] absolute bg-slate-900 z-50  w-1/2 left-[25%]"} open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="fixed inset-0 z-0  bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 flex w-screen h-screen items-center justify-center   ">
        <Panel className="mx-auto  flex flex-col gap-3 rounded dark:bg-gray-900 bg-slate-100 bg-opacity-35   h-full p-6  text-gray-950  w-full">
          <button
            onClick={() => setIsOpen(false)}
            className="text-2xl rounded-full z-30 border w-fit absolute top-2 right-4 border-red-600 text-red-600"
          >
            <IoClose />
          </button>
          <Map center={marker1} animate={true} zoomSnap={true} mouseEvents={true} touchEvents={true}>
            <ZoomControl />
            <Marker width={50} anchor={marker1}>
              <FaTruck className="text-green-500 text-2xl" />
            </Marker>
            <Marker anchor={marker2}>
              <FaBox className="text-2xl text-red-600" />
            </Marker>
          </Map>
        </Panel>
      </div>
    </Dialog>
  );
}

export default FullMap;
