import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function ListBox({ list, title, toggle }) {
  const [selected, setSelected] = useState(list[0].name);
  const { Button, Option, Options } = Listbox;

  return (
    <div className="top-16 z-0 w-60">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Button className="relative w-full cursor-default rounded-lg  bg-slate-300 dark:bg-slate-800  py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
          <Transition as={Fragment} leave="transition ease-in  duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Options className="absolute mt-1 z-30 max-h-60 w-full dark:text-gray-50 text-gray-950 overflow-auto rounded-md bg-slate-400 dark:bg-slate-700 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {list.map((item) => (
                <Option
                  onClick={() => toggle(item.url)}
                  key={item.name}
                  className={({ active }) =>
                    `relative cursor-default select-none z-30 py-2 pl-10 pr-4 dark:hover:bg-slate-600 dark:hover:text-gray-200 ${
                      active ? "bg-slate-300 dark:bg-slate-800 dark:text-gray-950" : "text-gray-100"
                    }`
                  }
                  value={item.name}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{item.name}</span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-black dark:text-white">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Option>
              ))}
            </Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
