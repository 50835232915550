import moment from "moment";

// Custom comparator function for sorting by date
const compareDates = (a, b) => {
  const A_time = moment(a.updated_at);
  const B_time = moment(b.updated_at);

  if (A_time.isBefore(B_time)) {
    return 1;
  }
  if (A_time.isAfter(B_time)) {
    return -1;
  }
  return 0;
};

export default compareDates;
