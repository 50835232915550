import axios from "axios";
export const baseUrl = "https://atoz-register.metariom.shop";
const RegesterConfigApi = axios.create({
  baseURL: baseUrl,
  headers: {
    "x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
  },
});

export default RegesterConfigApi;
