import Cookies from "js-cookie";
import { messageConfig } from "./messageAxiosConfig";

export const postMessageHandler = async ({ user_id, token }) => {
  console.log(user_id, token);
  const firebaseToken = Cookies.get("Firebase-Token");
  console.log("FIREBASETOKEN", firebaseToken);
  try {
    await messageConfig.post("/api/v1/message", {
      type: ["firebase"],
      user_id,
      token: [token, firebaseToken],
      title: "User Messaging",
      content: "  ",
    });
  } catch (error) {
    console.log(error);
  }
};

// 'type' => 'required|array',
// 'user_id' => 'required|array',
// 'sound' => 'nullable',//[1,0]
// 'status' => 'nullable',//[active,inactive]
// 'admin_id'=> 'nullable',

// if type firebase
// 'token' => 'required|array',
// 'title' => 'required',
// 'content' => 'required',
