// My Api To connect db get
import axios from "axios";
const messageUrl = "https://atoz-messenger-service.metariom.shop";

export const messageConfig = axios.create({
  baseURL: messageUrl,
  headers: {
    "x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
  },
});
