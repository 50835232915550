import React, { useContext } from "react";
import Layout from "./Layout";
import useTheme from "../Hooks/useTheme";

import { LocationApi } from "../Context/locationApi";
import { LogoutButton } from "../Components";

function Profile() {
  const { toggleTheme, darkMode } = useTheme();
  const { activeLocation, deAvtiveLocation } = useContext(LocationApi);

  return (
    <Layout title={"Profile"}>
      <section className="my-8 w-full h-full  px-6 flex flex-col py-4 ">
        <h3 className="col-auto text-lg text-blue-600 my-3 dark:text-amber-400">App Setting</h3>
        <div className="w-full  p-5 grid grid-cols-1 sm:grid-cols-2 bg-slate-200 dark:bg-slate-800  rounded h-fit">
          <div className="flex gap-4 col-span-1 flex-row items-center">
            <p>Theme Swicher</p>
            <button onClick={toggleTheme} className="bg-slate-800 rounded px-2 py-1 text-white dark:text-gray-900 dark:bg-slate-50">
              {darkMode ? "Light" : "Dark"} Mode
            </button>
          </div>
          <div className={`w-full py-2 col-span-1 justify-between gap-2 flex flex-col px-2 my-2   `}>
            <h3 className="my-4">App Access your Location?</h3>
            <div className="flex flex-wrap gap-4  justify-stretch items-center">
              <button onClick={() => activeLocation()} className=" bg-green-600 px-2 py-1   rounded-md     hover:bg-green-500 text-white">
                Active Location{" "}
              </button>
              <button onClick={() => deAvtiveLocation()} className=" bg-red-600 px-2 py-1   rounded-md   hover:bg-red-500 text-white">
                Deactive Location
              </button>
            </div>
          </div>
        </div>
        <h3 className=" text-lg text-blue-600 my-3 dark:text-amber-400">User Setting</h3>
        <div className="w-full  my-5 p-5 grid grid-cols-1 sm:grid-cols-3 bg-slate-200 dark:bg-slate-800  rounded h-fit">
          User Logout <LogoutButton />
        </div>
      </section>
    </Layout>
  );
}

export default Profile;
