import React, { useContext, useState } from "react";
import axiosConfig from "../Config/RegisterApiConfig";
import { contextApi } from "../Context/contextApi";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

import CheckoutWizard from "./CheckoutWizard";

import FormNav from "./FormNav";
import useLoader from "../Hooks/useLoader";

function Singcontract() {
  const [contract, setContract] = useState(false);
  const [terms, setTerms] = useState(false);
  const { state } = useContext(contextApi);
  const navigate = useNavigate();
  const { setLoader, removeLoader } = useLoader();

  const submitHandler = async () => {
    const { token } = state;
    if (!contract) return;
    if (!terms) return;
    setLoader();
    try {
      const res = await axiosConfig.post(
        "/api/v1/register/sign-contract",
        { sign_contract: true, terms: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(res);
      const { status, data } = res;
      console.log(data, status);
      if (status === 200) {
        removeLoader();
        navigate("/");
        const { message } = data;
        toast.success(message);
      }
    } catch (err) {
      removeLoader();
      console.log(err);
      console.log(err.response.data);
      toast.error(err?.message);
    }
  };
  return (
    <>
      <FormNav link={"/form/phoneVerify"} title={"Sing Contract"} />
      <CheckoutWizard activeStep={6} />
      <div className="flex w-[95%] relative md:w-4/5 mx-auto flex-col gap-3">
        {/* <h3 className="text-lg md:text-xl font-semibold text-amber-400">Sing Contract</h3> */}
        <div className="my-4 flex flex-col items-start gap-3 justify-start">
          <h3 className="text-lg text-gray-950 dark:text-gray-50"> The last step is to accept the Terms and Sing Contract</h3>
          <div className="py-3 flex flex-col items-start gap-1">
            <div className="flex items-center justify-start gap-1 ">
              <Checkbox value={contract} onChange={() => setContract(!contract)} color="primary" sx={{ padding: "0.1rem" }} />
              <p className="text-gray-950 dark:text-gray-50">Accept Contract</p>
            </div>
            <div className="flex items-center justify-start gap-1 ">
              <Checkbox value={terms} onChange={() => setTerms(!terms)} color="primary" sx={{ padding: "0.1rem" }} />
              <p className="text-gray-950 dark:text-gray-50">Accept Rules and Terms</p>
            </div>
          </div>
          <button
            className={`self-start  cursor-pointer transition-all ease-in  px-4 py-2 rounded-md text-white ${
              contract && terms ? "bg-blue-500" : "bg-gray-600"
            }`}
            type="button"
            onClick={() => submitHandler()}
            disabled={terms && contract}
          >
            Sing Contract
          </button>
        </div>
      </div>
    </>
  );
}

export default Singcontract;
