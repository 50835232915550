import React from "react";
import { NavLink } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const Navbar = () => {
  return (
    <nav className="w-screen sticky z-50 top-0 bg-zinc-300 dark:bg-amber-400  hidden sm:flex  text-slate-950 text-base p-2 py-3  justify-start gap-2 items-center">
      <ul className="flex w-full items-center flex-row  gap-4 px-4 py-1 text-base font-semibold">
        <li>
          <NavLink
            className={({ isActive, isPending }) =>
              isActive
                ? "flex items-center gap-1 border-b-2 transition-all ease-linear  text-blue-500 dark:border-gray-950 dark:text-slate-950 border-blue-500"
                : "flex items-center gap-1"
            }
            to={"/"}
          >
            <FaHome /> Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive, isPending }) =>
              isActive
                ? "flex items-center gap-1 border-b-2 transition-all ease-linear   text-blue-500 dark:border-gray-950 dark:text-slate-950 border-blue-500"
                : "flex items-center transition-all ease-linear gap-1"
            }
            to={"/loads"}
          >
            Loads
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive, isPending }) =>
              isActive
                ? "flex items-center gap-1 border-b-2 transition-all ease-linear   text-blue-500 dark:border-gray-950 dark:text-slate-950 border-blue-500"
                : "flex items-center transition-all ease-linear gap-1"
            }
            to={"/truck"}
          >
            Truck
          </NavLink>
        </li>
        <li className="place-items-end ">
          <NavLink
            className={({ isActive, isPending }) =>
              isActive
                ? "flex items-center gap-1 border-b-2 transition-all ease-linear  text-blue-500 dark:border-gray-950 dark:text-slate-950 border-blue-500"
                : "flex items-center transition-all ease-linear gap-1"
            }
            to={"/profile"}
          >
            Profile
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
