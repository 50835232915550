import { useContext } from "react";
import { contextApi } from "../Context/contextApi";
import { fetchWithToken } from "../Utils/fetcher";
import useSwr from "swr";

const useTrcukList = () => {
  const { state } = useContext(contextApi);
  const { token } = state;

  const { data, error, isLoading } = useSwr(["/api/v1/register/truck-type", token], ([url, token]) => fetchWithToken(url, token));

  return {
    data: data?.data?.data,
    error,
    isLoading,
  };
};

export default useTrcukList;
