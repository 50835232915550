import { useCallback, useContext, useState, useEffect, useMemo } from "react";
import { contextApi } from "../Context/contextApi";
import { toast } from "react-toastify";
import RegesterConfigApi from "../Config/RegisterApiConfig";
import Cookies from "js-cookie";

const useInfo = () => {
  const { state } = useContext(contextApi);
  const [userData, setUserData] = useState(null);
  // const { token } = state;
  // const cookie = Cookies.get("atoz-coolies");
  const { token } = useMemo(() => (Cookies.get("atoz-coolies") ? JSON.parse(Cookies.get("atoz-coolies")) : state.token), [state]);

  const getUserInfo = useCallback(async () => {
    try {
      const { data, status } = await RegesterConfigApi.get("/api/v1/register/personal-info", { headers: { Authorization: `Bearer ${token}` } });
      if (status === 200) {
        setUserData(data?.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response);
    }
  }, [token]);
  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);
  return { data: userData };
};

export default useInfo;
