import React, { useState, useCallback, useContext } from "react";
import { Dialog } from "@headlessui/react";

import { IoClose } from "react-icons/io5";
import Input from "./Input";
import TextAria from "./TextAria";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { contextApi } from "../Context/contextApi";
import RegesterConfigApi from "../Config/RegisterApiConfig";

function BookingModal({ isOpen, setIsOpen, data, denine, style }) {
  const { id, load_miles, load_pick_up, load_deliver_to, price: propsedPrice, load_notes } = data;
  const { Panel } = Dialog;
  const navigate = useNavigate();
  const { state } = useContext(contextApi);
  const [price, setPrice] = useState(propsedPrice);

  const sendOfferHandler = useCallback(async () => {
    const offerData = {
      offer_price: price,
      load_id: id,
    };
    console.log(offerData);

    try {
      const resData = await RegesterConfigApi.post("/api/v1/user/store-offer-load", offerData, {
        headers: { Authorization: "Bearer " + state?.token },
      });
      console.log(resData);
      const { status, data } = resData;
      if (status === 200) {
        toast.success(data?.message);
        navigate("/loads");
      }
    } catch (error) {
      console.log(error);
    }
  }, [id, price, navigate, state]);

  return (
    <Dialog className={`top-[25%] absolute bg-slate-900 z-50  w-1/2 left-[25%] ${style}`} open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="fixed inset-0 z-0  bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
        <Panel className="mx-auto  flex flex-col gap-3 rounded dark:bg-gray-900 bg-slate-100   h-fit p-6  dark:text-gray-50 text-gray-950  w-full md:w-1/2">
          <button onClick={() => setIsOpen(false)} className="text-2xl rounded-full z-30 border w-fit absolute right-10 border-red-600 text-red-600">
            <IoClose />
          </button>

          <div className="p-6 w-full">
            <h3 className="dark:text-white text-lg font-semibold py-2 text-black border-b border-gray-900 dark:border-gray-100">Load Information</h3>
            <div className="flex flex-col  my-4">
              <h2 className="dark:text-gray-400 text-gray-700 text-sm">
                Load Pick up
                <span className="text-base mx-2 dark:text-gray-50 text-gray-950 font-bold"> {load_pick_up}</span>
              </h2>
              <h2 className="dark:text-gray-400 text-gray-700 text-sm">
                Load Drop off
                <span className="text-base mx-2 dark:text-gray-50 text-gray-950 font-bold">{load_deliver_to}</span>
              </h2>
              <h3 className="dark:text-gray-400 text-gray-700 text-sm">
                Distance
                <span className="text-base mx-2 dark:text-gray-50 text-gray-950 font-bold">{load_miles} miles</span>
              </h3>
              <h3 className="dark:text-gray-400 text-gray-700 text-sm">
                Propsed Price
                <span className="text-base mx-2 dark:text-gray-50 text-gray-950 font-bold">{propsedPrice} USD</span>
              </h3>
              <span className="w-full my-2">
                <Input id={"price"} label={"Price"} onChange={(e) => setPrice(e.target.value)} type={"text"} value={price} />
              </span>
              <h3 className="dark:text-gray-400 text-gray-700 text-sm">
                Description
                <span className="text-base mx-2 dark:text-gray-50 text-gray-950 font-bold">{load_notes}</span>
              </h3>
              <TextAria />
            </div>
            <div className="flex justify-between  py-4 items-center">
              <button
                onClick={() => sendOfferHandler()}
                className="px-4 py-2 w-1/3 rounded-md text-white bg-green-600 hover:bg-green-500 transition-all ease-linear"
              >
                Confirm
              </button>
              <button onClick={denine} className="px-4 py-2 w-1/3 rounded-md text-white bg-red-600 hover:bg-red-500 transition-all ease-linear">
                Denine
              </button>
            </div>
          </div>
        </Panel>
      </div>
    </Dialog>
  );
}

export default BookingModal;
