import useSwr from "swr";
import fetcher from "../Utils/fetcher";

const useCodeList = () => {
  const { data, error, isLoading } = useSwr(
    "/api/v1/register/get-country-code",
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return { data: data, error, isLoading };
};

export default useCodeList;
