import React, { useState, useCallback, useEffect } from "react";
import Layout from "./Layout";
// import useLoadData from "../Hooks/useLoadData";
import { ListBox, LoadData } from "../Components";
import Skeleton from "@mui/material/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";
import useLoadData from "../Hooks/useLoadData";

function Loads() {
  const [count, setCount] = useState(10);
  const [list, setList] = useState(null);
  const [meatData, setMetaData] = useState({ links: [] });
  // const [params, setParams] = useState(`?take=100`);
  const { loadData, error, isLoading } = useLoadData();
  const [hasMore, setHasMore] = useState(true);

  // const [pages, setPages] = useState([]);
  useEffect(() => {
    if (loadData && error == null) {
      const { current_page, links, total, per_page } = loadData;
      setList(() => [...loadData.data.slice(0, count)]);
      setMetaData({ current_page, links, total, per_page });
    }
  }, [count, loadData, error]);

  // Retire Features
  // const { loadData, isLoading, error } = useLoadData({ params });
  // const toggleParams = useCallback((str) => {
  //   setParams(`?take=100${str}`);
  // }, []);
  // const sortList = [
  //   { name: "default", url: "" },
  //   { name: "Ascending", url: "?orderby=created_at,asc" },
  //   { name: "Descending", url: "?orderby=id,desc" },
  // ];
  // Retire Features

  const statusList = [
    { name: "All", url: "all" },
    { name: "Rejected", url: "reject" },
    { name: "Accepted", url: "accept" },
    { name: "Pending", url: "pending" },
  ];
  const filterList = useCallback(
    (status) => {
      console.log(status);
      if (error) return;
      if (status === "all") {
        setList([...loadData.data]);
      } else {
        const filterList = loadData?.data.filter((item) => item.status === status);
        setList([...filterList]);
      }
    },
    [loadData, error]
  );

  const fetchMoreData = useCallback(() => {
    if (loadData && error == null) {
      console.log(count);
      setList(() => loadData.data.slice(0, count + 10));
      setCount(() => 10 + count);
      if (count >= meatData?.total) setHasMore(false);
    }
  }, [count, loadData, error, meatData]);

  return (
    <Layout title={"Loads & Trucks"}>
      <div className="w-full h-screen overflow-y-auto overflow-x-hidden mb-10">
        <div className="flex w-full gap-3 my-6 px-4 flex-wrap flex-row justify-start items-center  ">
          <h3 className="text-base md:text-lg text-gray-900  dark:text-gray-50 text-left  px-4 ">
            {isLoading ? (
              <Skeleton width={150} variant="text" />
            ) : list == null ? (
              <Skeleton width={150} variant="text" />
            ) : list?.length === 0 ? null : (
              `Found ${list?.length} Load's`
            )}
          </h3>
          <div className="flex gap-2 flex-row  w-full sm:w-1/2  items-center">
            {!isLoading && <ListBox title={"Get Loads"} toggle={filterList} list={statusList} />}
          </div>
        </div>

        {!isLoading && (
          <InfiniteScroll
            className=" h-screen overflow-x-hidden  grid gap-4 my-4 mb-10 p-3 grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 px-6"
            dataLength={count}
            next={fetchMoreData}
            hasMore={hasMore}
          >
            {list?.length === 0 && <h3 className="font-semibold text-lg">There are no load or offrer for you</h3>}
            {list?.map((item) => (
              <LoadData key={item.id} data={item} />
            ))}
            {/* // pagination */}
            {!hasMore ? (
              <div className="col-span-full p-2 text-slate-50 flex items-center justify-center">
                <button
                  className={`rounded-s-md transition-all ease-in dark:bg-amber-400 bg-blue-500 ${
                    meatData?.links[0]?.active ? "hover:bg-blue-700 dark:hover:bg-amber-500" : null
                  } px-3 py-2`}
                >
                  <FaAngleDoubleLeft />
                </button>
                <button
                  className={`px-5 py-1 transition-all ease-in dark:bg-amber-400 bg-blue-500 ${
                    meatData?.links[1]?.active ? "dark:hover:bg-amber-500" : null
                  } border-x`}
                >
                  {meatData?.current_page}
                </button>
                <button
                  className={`rounded-e-md transition-all ease-in dark:bg-amber-400 bg-blue-500 ${
                    meatData?.links[2]?.active ? "dark:hover:bg-amber-500" : null
                  }  px-3 py-2`}
                >
                  <FaAngleDoubleRight />
                </button>
              </div>
            ) : null}
          </InfiniteScroll>
        )}
      </div>
    </Layout>
  );
}

export default Loads;
