import React from "react";
import useCodeList from "../Hooks/useCode";

const PhoneCode = ({ regester, disable, selected = null, error = null }) => {
  const { data: values } = useCodeList();

  return (
    <>
      <label htmlFor="underline_select" className="sr-only   bg-slate-400">
        Country Code
      </label>
      <select
        disabled={disable}
        {...regester}
        id="underline_select"
        className="block py-2.5 px-2 w-full text-sm  text-gray-950  dark:text-gray-100 bg-transparent border-0 border-b-2 border-gray-200 appearance-none   bg-slate-300   focus:outline-none  focus:ring-0 focus:border-gray-200 focus:bg-slate-200 dark:focus:bg-slate-900 rounded   peer"
        aria-invalid={error}
      >
        {values &&
          values.map((item) => (
            <option key={item?.code} className="px-2 bg-slate-200 dark:bg-slate-900" value={item?.code}>
              {item?.name} {item?.dial_code}
            </option>
          ))}
      </select>
    </>
  );
};

export default PhoneCode;
