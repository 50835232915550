// api/v1/admin/get-loads
import axios from "axios";
const baseUrl = "https://atoz-loadal-location.metariom.shop";
const LoadConfigApi = axios.create({
  baseURL: baseUrl,
  headers: {
    "x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
  },
});

export default LoadConfigApi;
