import React, { useContext, useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { contextApi } from "../Context/contextApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, ImageUploader, CheckoutWizard, Checkbox, FormNav } from "./";
import Cookies from "js-cookie";
import useLoader from "../Hooks/useLoader";
import { FaArrowRight } from "react-icons/fa";
import Alert from "@mui/material/Alert";
import RegesterConfigApi from "../Config/RegisterApiConfig";

function BankInfo() {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState(false);
  const [letter, setLetter] = useState([]);
  const [confirm, setConfirm] = useState([]);

  const { state } = useContext(contextApi);
  const [pay, setPay] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const { setLoader, removeLoader } = useLoader();

  const inputes = [
    {
      id: "name",
      label: "name",
      type: "text",
      regester: { ...register("name", { required: "Your Name is required" }) },
      error: errors.name ? { status: true, message: errors?.name?.message } : "false",
    },
    {
      id: "account_number",
      label: "Account Number",
      type: "text",
      regester: { ...register("account_number", { required: "Account Number is required" }) },
      error: errors.account_number ? { status: true, message: errors?.account_number?.message } : "false",
    },
    {
      id: "account_number_confirm",
      label: "Account Number Confirm",
      type: "text",
      regester: {
        ...register("account_number_confirm", {
          required: "Account Number Confirm is required",
          deps: "account_number",
          validate: (value) => value === getValues("account_number") || "Account Number and Confirm Are not Same",
        }),
      },
      error: errors.account_number_confirm ? { status: true, message: errors?.account_number_confirm?.message } : "false",
    },
    {
      id: "routing_number",
      label: "Routing Number",
      type: "text",
      regester: { ...register("routing_number", { required: "Routing Number is required" }) },
      error: errors.routing_number ? { status: true, message: errors?.routing_number?.message } : "false",
    },
    {
      id: "routing_number_confirm",
      label: "Routing Number Confirm",
      type: "text",
      regester: {
        ...register("routing_number_confirm", {
          required: "Routing Number Confirm is required",
          deps: "routing_number",
          validate: (value) => value === getValues("routing_number") || "Rounting Number and Confirm Are not Same",
        }),
      },
      error: errors.routing_number_confirm ? { status: true, message: errors?.routing_number_confirm?.message } : "false",
    },
  ];
  const Images_fiels = [
    {
      MaxImages: 5,
      images: letter,
      setImages: setLetter,
      title: "Bank Confirmation Letter",
    },
    {
      MaxImages: 5,
      images: confirm,
      setImages: setConfirm,
      title: "Bank Confirmation Check",
    },
  ];
  const onSubmitHandler = async (data) => {
    if (letter?.length === 0 && confirm?.length === 0) return setError(true);
    setError(false);
    const allData = {
      ...data,
      quick_pay: pay ? 1 : 0,
    };
    setLoader();
    const formData = new FormData();
    Object.entries(allData).forEach(([key, value]) => formData.append(key, value));
    letter.forEach((item) => {
      formData.append("bank_confirmation_letter[]", item);
    });
    confirm.forEach((item) => {
      formData.append("bank_confirmation_check[]", item);
    });
    const conformAccount = data.account_number === data.account_number_confirm;
    if (!conformAccount) return;
    // const conformAccount=
    try {
      const { token } = state;
      const res = await RegesterConfigApi.post("/api/v1/register/bank-info-store", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });
      Cookies.set("bank_info", JSON.stringify(allData));
      console.log(res);
      if (res.status === 200) {
        navigate("/form/phoneVerify");
        toast.success(res.data?.message);
        removeLoader();
      }
    } catch (err) {
      console.log(err);
      removeLoader();
    }
  };
  const bank_info = useMemo(() => (Cookies.get("bank_info") ? JSON.parse(Cookies.get("bank_info")) : null), []);
  useEffect(() => {
    if (bank_info) {
      Object.entries(bank_info).forEach(([key, value]) => {
        setValue(key, value);
      });

      setPay(() => (bank_info.quick_pay === 1 ? true : false));
      setDisabled(true);
    }
  }, [setValue, bank_info]);
  return (
    <>
      <FormNav title={"Bank Account Information"} link={"/form/licence"} />
      <CheckoutWizard activeStep={4} />
      <form className="w-[95%] relative md:w-4/5 mx-auto flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
        {/* <h3 className="text-lg md:text-xl font-semibold text-amber-400">Bank Information</h3> */}
        <div className=" flex flex-col  gap-2 my-2">
          {inputes.map((input) => (
            <Input modal={disabled} key={input.id} {...input} />
          ))}
          <Checkbox disabled={disabled} title={"Quick Pay"} value={pay} onChange={setPay} />
          {Images_fiels.map((item) => (
            <ImageUploader images={item.images} setImages={item.setImages} title={item.title} key={item.title} />
          ))}
          {error && (
            <Alert variant="filled" className="my-2" severity="error">
              Confirm and Letter image should uploaded
            </Alert>
          )}
          <button className="self-start mt-4 flex items-center gap-2  px-4 py-2 rounded-md text-white bg-blue-500" type="submit">
            Next <FaArrowRight className="inline" />
          </button>
        </div>
      </form>
    </>
  );
}

export default BankInfo;
