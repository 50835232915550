import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Components/Navbar";
import MobileMenu from "../Components/MobileMenu";
import { LocationApi } from "../Context/locationApi";
// import { Footer } from "../Components";

function Layout({ children, title }) {
  const { userLocation, getLocation } = useContext(LocationApi);

  return (
    <section className="w-full h-full relative flex   flex-col gap-0 md:gap-3  items-center">
      <div className="sticky sm:hidden py-3 px-2 flex flex-col  justify-center  items-center">
        <div className="w-full flex justify-center items-center gap-2">
          <p className="dark:text-gray-200 italic text-gray-950 ">{!getLocation ? "offline" : "online"}</p>
          <button className={` w-3 h-3 rounded-full top-12 left-10 ${!getLocation ? "bg-red-600" : "gps_light "}`}></button>
        </div>

        {userLocation && (
          <div className="flex gap-2 items-center">
            <p>latitude: {userLocation?.latitude}</p>
            <p>longitude: {userLocation?.longitude}</p>
          </div>
        )}
      </div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Navbar />
      <MobileMenu />
      {children}
      {/* <Footer /> */}
    </section>
  );
}

export default Layout;
