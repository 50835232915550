import React, { useState, useEffect } from "react";
import Layout from "./Layout";

import { FaTruckFast, FaMotorcycle, FaPlaneDeparture } from "react-icons/fa6";
import { FaGlobeAsia } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { ConfirmModal, LoadData, LoadingData } from "../Components";
// import Skeleton from "@mui/material/Skeleton";
import useUserLoad from "../Hooks/useUserLoads";
import useInfo from "../Hooks/useInfo";

function Home() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { data } = useInfo();

  const { loadData } = useUserLoad(data?.id);
  const handleBeforeInstallPrompt = (event) => {
    // Prevent the default behavior
    event.preventDefault();
    // Stash the event so it can be triggered later.
    console.log(event);
    setDeferredPrompt(event);
    // Optionally, show your own install prompt
    // For example:
    setShowInstallButton(true);
  };
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(display-mode: standalone)");
    const isPWAInstalled = mediaQueryList.matches;

    setOpenModal(!isPWAInstalled);
    setIsInstalled(isPWAInstalled);

    // Listen for changes in display mode (in case user installs or uninstalls the PWA)
    const handleChange = (event) => {
      setIsInstalled(event.matches);
    };
    mediaQueryList.addEventListener("change", handleChange);
    return () => {
      mediaQueryList.removeEventListener("change", handleChange);
    };
  }, [isInstalled, showInstallButton]);

  const handleInstallClick = () => {
    // Show the browser install prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Optionally, you can redirect here if you want
          console.log("User accepted the install prompt");
          window.location.href = "/installed";
        }
        setDeferredPrompt(null);
      });
    }
    setOpenModal(false);
  };

  return (
    <Layout title={"Home Page"}>
      <section className="w-full my-6  h-screen overflow-y-auto px-6 py-4">
        <article className="w-full h-fit py-8 grid grid-cols-2   mb-4  gap-4  lg:grid-cols-4 bg-slate-200 dark:bg-slate-800 rounded p-4">
          <h3 className="text-2xl col-span-2 lg:col-span-4">Book vehicle for:</h3>
          <div className="col-span-1 bg-neutral-100 dark:bg-gray-900 shadow-lg dark:shadow-slate-700 border border-slate-400 dark:border-neutral-600 py-6 gap-4 rounded px-4  hover:shadow-xl transition-all ease-linear flex flex-col justify-around items-start">
            <div className="text-2xl">
              <FaTruckFast className="text-5xl" />
            </div>
            <span className="flex justify-between peer w-full items-center">
              <p>Choose Form our fleet</p>
              <IoIosArrowForward className="text-lg text-white peer-hover:translate-x-2 " />
            </span>
          </div>
          <div className="col-span-1 bg-neutral-100 dark:bg-gray-900 shadow-lg dark:shadow-slate-700 border border-slate-400 dark:border-neutral-600 py-6 gap-4 rounded px-4  hover:shadow-xl transition-all ease-linear flex flex-col justify-around items-start">
            <div className="text-2xl">
              <FaMotorcycle className="text-5xl" />
            </div>
            <span className="flex justify-between peer w-full items-center">
              <p>For smaller goods</p>
              <IoIosArrowForward className="text-lg text-white peer-hover:translate-x-2 " />
            </span>
          </div>
          <div className="col-span-1 bg-neutral-100 dark:bg-gray-900 shadow-lg dark:shadow-slate-700 border border-slate-400 dark:border-neutral-600 py-6 gap-4 rounded px-4  hover:shadow-xl transition-all ease-linear flex flex-col justify-around items-start">
            <div className="text-2xl">
              <FaPlaneDeparture className="text-5xl" />
            </div>
            <span className="flex justify-between peer w-full items-center">
              <p>Access for air transport</p>
              <IoIosArrowForward className="text-lg text-white peer-hover:translate-x-2 " />
            </span>
          </div>
          <div className="col-span-1 bg-neutral-100 dark:bg-gray-900 shadow-lg dark:shadow-slate-700 border border-slate-400 dark:border-neutral-600 py-6 gap-4 rounded px-4  hover:shadow-xl transition-all ease-linear flex flex-col justify-around items-start">
            <div className="text-2xl">
              <FaGlobeAsia className="text-5xl" />
            </div>
            <span className="flex justify-between peer w-full items-center">
              <p>All Asia Countery</p>
              <IoIosArrowForward className="text-lg text-white peer-hover:translate-x-2 " />
            </span>
          </div>
        </article>
        <article className="w-full h-fit py-8  mb-4  gap-4    bg-slate-200 dark:bg-slate-800 rounded p-4">
          <h3 className="text-2xl col-span-2 lg:col-span-4">My Offers:</h3>

          {loadData ? (
            <div className="h-full overflow-x-hidden grid gap-4 my-4 mb-10 p-3 grid-cols-1  sm:grid-cols-2 lg:grid-cols-3">
              {loadData?.data?.map((load) => (
                <LoadData key={load?.id} data={load} />
              ))}
            </div>
          ) : (
            <LoadingData size={5} />
          )}
        </article>
      </section>

      <ConfirmModal
        confirm={handleInstallClick}
        denine={() => setOpenModal(false)}
        isOpen={openModal}
        setIsOpen={setOpenModal}
        title={"Are you want install app on your device?"}
        style={`md:hidden`}
      />
    </Layout>
  );
}

export default Home;
