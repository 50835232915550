import React, { useCallback, useState } from "react";

import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import Alert from "@mui/material/Alert";

const Input = ({ id, onChange, value, label, type: valueType, modal = false, regester = null, error = null }) => {
  const [type, setType] = useState(valueType);
  const toggleType = useCallback(() => {
    if (type === "password") return setType("text");
    setType("password");
  }, [type]);

  return (
    <div className="relative">
      <input
        disabled={modal}
        className="
          block
          rounded-md
          px-6
          pt-6
          pb-1
          w-full
          text-md
        text-blac
        bg-slate-200
        text-black
        dark:bg-slate-900
        dark:text-slate-50
          appearance-none
          focus:outline-none
          focus:ring-0
          peer
          invalid:border-b-1
           "
        placeholder=""
        type={type}
        id={id}
        onChange={onChange}
        {...regester}
        aria-invalid={error}
        value={value}
      />
      <label
        htmlFor={id}
        className={`       absolute 
         
        text-gray-700
        dark:text-gray-100
          duration-150 
          transform 
          -translate-y-3 
          scale-75 
          top-4 
          z-10 
          origin-[0] 
          left-2
          peer-placeholder-shown:scale-100 
          peer-placeholder-shown:translate-y-0 
          peer-focus:scale-75
          peer-focus:-translate-y-3 ${modal ? "text-sm" : "text-md"}`}
      >
        {label}
      </label>
      {/* for password input can toggle unvisible/visible password  */}
      {id === "password" && (
        <div onClick={toggleType} className={`absolute  right-2  transition-all  top-5  cursor-pointer`}>
          {type !== "password" ? (
            <FaRegEye className="text-base text-gray-900 transition-all ease-linear dark:text-gray-100" />
          ) : (
            <FaRegEyeSlash className="text-base text-gray-900 transition-all ease-linear dark:text-gray-100" />
          )}
        </div>
      )}
      {/* for password input can toggle unvisible/visible password  */}
      {/* Custom password error */}
      {error?.status && (
        <Alert variant="filled" className="my-2" severity="error">
          {error?.message}
        </Alert>
      )}
    </div>
  );
};

export default Input;
