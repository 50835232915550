import React, { useEffect, useCallback, useMemo, useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { contextApi } from "../Context/contextApi";

import useInfo from "../Hooks/useInfo";
import moment from "moment";
import { IoCheckmarkDoneSharp, IoCheckmarkSharp } from "react-icons/io5";
const messageUrl = "https://atoz-register.metariom.shop/api/v1/user/get-offer-load/messages";

function ChatModal({ load_id = null, offers }) {
  const { state } = useContext(contextApi);
  const token = useMemo(() => (Cookies.get("atoz-coolies") ? JSON.parse(Cookies.get("atoz-coolies"))?.token : state?.token), [state]);
  const [text, setText] = useState("");
  const [messges] = useState(offers);

  const { data } = useInfo();

  const seenMessageHandler = useCallback(async () => {
    const unSeenMessages = messges.find((item) => item?.creator_id !== data?.id && item?.status === "unseen");
    // /api/v1/user/get-offer-load/messages/seen
    if (unSeenMessages) {
      try {
        await axios.post(
          `${messageUrl}/seen`,
          { load_id },
          {
            headers: {
              "x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [messges, data, token, load_id]);

  const messageSendHandler = useCallback(async () => {
    try {
      const { status } = await axios.post(
        messageUrl + "/store",
        {
          load_id,
          message: text,
        },
        {
          headers: {
            "x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (status === 200) {
        setText("");
      }
    } catch (error) {
      console.log(error);
    }
  }, [load_id, token, text]);

  useEffect(() => {
    if (messges.length !== 0) {
      seenMessageHandler();
    }
  }, [load_id, messges, seenMessageHandler]);

  return (
    <div className={`w-full bg-slate-900  rounded-md   `}>
      <div className="mx-auto  flex flex-col rounded-md dark:bg-gray-600  bg-slate-100 h-[36rem] w-full shadow-md">
        <div className="w-full text-white h-10 flex justify-between  rounded-t items-center px-2 py-1 dark:bg-amber-400 bg-blue-500">
          <h3 className="text-white">Load Messages</h3>
        </div>
        <div className="h-full w-full bg-gradient-to-br from-gray-100 to-gray-300 dark:bg-gradient-to-b dark:from-gray-700 dark:via-gray-900 dark:to-black rounded-b-md flex flex-col justify-between">
          <div className="h-full overflow-y-auto bg-transparent w-full flex flex-col justify-start px-4 py-2 gap-2 ">
            {messges?.map((item) => {
              return (
                <p
                  key={item.id}
                  className={`px-5 py-3 text-sm rounded-xl relative text-gray-500 ${
                    item?.creator_id !== data?.id
                      ? "bg-gradient-to-r from-orange-400 to-rose-400 self-start rounded-ss-none"
                      : "rounded-se-none bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400 self-end"
                  } `}
                >
                  {item?.message} Price: {item?.price}$
                  <span className={`absolute bottom-0 text-[0.5rem] text-gray-700 ${item?.creator_id === data?.id ? "left-2" : "right-2"}`}>
                    {item.updated_at ? moment(item?.updated_at).format("h:mm") : null}
                  </span>
                  <span
                    className={`absolute text-xs bottom-1 text-gray-700 ${item?.creator_id == null ? "left-2" : ""}  ${
                      item?.creator_id === data?.id ? "right-2" : ""
                    }}`}
                  >
                    {item?.status === "unseen" ? <IoCheckmarkSharp /> : <IoCheckmarkDoneSharp />}
                  </span>
                </p>
              );
            })}
          </div>
          <div className="h-12 px-2 dark:bg-amber-400 gap-1 bg-blue-500 flex flex-row-reverse justify-between  items-center  rounded-b-md w-full">
            <button onClick={messageSendHandler} className="w-1/5 px-2 py-1 rounded-md text-white bg-amber-400 dark:bg-blue-500">
              Send
            </button>
            <input
              className="text-gray-700 p-1 outline-none focus:outline-none focus:border-gray-200 transition-all ease-in border ring-1 w-4/5 rounded-md bg-transparent border-gray-50 placeholder:text-gray-600"
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Send message"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatModal;
