import React from "react";

export default function CheckboxCom({ value, onChange, title, disabled }) {
  return (
    <>
      <div className="flex items-center  px-2">
        <input
          value={value}
          onChange={() => onChange(!value)}
          disabled={disabled}
          id="default-checkbox"
          type="checkbox"
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-400 dark:ring-offset-gray-800 focus:ring-2   "
        />

        <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium dark:text-gray-50 text-gray-900  ">
          {title}
        </label>
      </div>
    </>
  );
}

// export default Checkbox;
// 'company_name' => 'nullable',
// 'citizenship_ein' => 'required',
// 'mc_number' => 'required',
