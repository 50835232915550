import React, { useContext, useEffect, useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./Utils/PrivateRoutes";
import { Home, Profile, Auth, Loads, Regester, Admin, Form, SingleLoad, Error, Truck } from "./Layout";
import { Info, BankInfo, TrucksInfo, Liecence, Singcontract, PhoneVerify } from "./Components";
import { contextApi } from "./Context/contextApi";
import Main from "./Main";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { dispatch } = useContext(contextApi);
  const theme = useMemo(() => window.matchMedia("(prefers-color-scheme: dark)").matches, []);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", theme);
    if (theme) window.localStorage.setItem("theme", theme ? "dark" : "light");
  }, [theme]);

  useEffect(() => {
    dispatch({ type: "UPDATE_STATE" });
  }, [dispatch]);

  if (!window.Promise) {
    window.Promise = Promise;
  }

  return (
    <main className={"w-screen relative  h-fit min-h-[100vh]  bg-slate-100 dark:bg-slate-900 text-gray-950 dark:text-white"}>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route element={<Main />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="loads" element={<Loads />} />
            <Route path="load/:id" element={<SingleLoad />} />
            <Route path="profile" element={<Profile />} />
            <Route path="truck" element={<Truck />} />
            <Route path="admin" element={<Admin />} />
          </Route>
        </Route>
        <Route path="auth" element={<Auth />} />

        <Route path="register" element={<Regester />} />
        <Route path="form" element={<Form />}>
          <Route index element={<Info />} />
          <Route path="truck" element={<TrucksInfo />} />
          <Route path="licence" element={<Liecence />} />
          <Route path="bank" element={<BankInfo />} />
          <Route path="phoneVerify" element={<PhoneVerify />} />
          <Route path="contract" element={<Singcontract />} />
        </Route>

        {/* 404 pages */}
        <Route path="*" element={<Error />} />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme ? "dark" : "light"}
      />
    </main>
  );
}
export default App;
