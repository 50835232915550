import axios from "axios";
import useSwr from "swr";

const baseURL = "https://atoz-loadal-location.metariom.shop";
const AxiosConfig = axios.create({
  baseURL,
  headers: {
    " x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
  },
});
const fetcher = (url) => AxiosConfig.get(url).then((res) => res.data);

const useLoadID = (id) => {
  const { data, isLoading, error } = useSwr(`api/v1/user/get-load/${id}`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  if (id == null) {
    return { error: "id is Undefined" };
  }
  return { loadData: data?.data, error, isLoading };
};

export default useLoadID;
