// serviceWorkerRegistration.js

export function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("./serviceWorker.js", { type: "module" })
        .then((registration) => {
          // console.log("ServiceWorker registered successfully:", registration);
        })
        .catch((error) => {
          console.error("ServiceWorker registration failed:", error);
        });
      navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then((registration) => {
          // console.log("firebase-messaging-sw successfully:", registration);
        })
        .catch((error) => {
          console.error("firebase-messaging-sw registration failed:", error);
        });
    });
  } else {
    console.log("Service workers are not supported in this browser.");
  }
}
