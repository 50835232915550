// firebase.js
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import Cookies from "js-cookie";

var firebaseConfig = {
  apiKey: "AIzaSyB0HuxIV74rCJjEXbjrfvkV0N7ZN68akp8",
  authDomain: "vue-web-app-7bfca.firebaseapp.com",
  projectId: "vue-web-app-7bfca",
  storageBucket: "vue-web-app-7bfca.appspot.com",
  messagingSenderId: "549967541659",
  appId: "1:549967541659:web:af32ea2ca9d244100c9d3e",
  measurementId: "G-96DHB0734V",
};

export const app = firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging();

export function initFirebaseMessagingRegistration() {
  // Your notification logic goes here
  // For example, you can create a new notification

  messaging
    .getToken()
    .then(function (currentToken) {
      if (currentToken) {
        Cookies.set("Firebase-Token", currentToken);
        firebase = currentToken;
      } else {
        console.log("No Instance ID token available. Request permission to generate one.");
      }
    })
    .catch(function (err) {
      console.error("An error occurred while retrieving token. ", err);
    });
}

// Handle incoming messages while the app is in the foreground
messaging.onMessage((payload) => {
  console.log("Message received:", payload);
  // Customize handling of incoming messages
});

messaging.onMessage(function (payload) {
  const noteTitle = payload.notification.title;
  const noteOptions = {
    body: payload.notification.body,
    icon: payload.notification.icon,
  };
  new Notification(noteTitle, noteOptions);
});
