import React from "react";
import { useNavigate } from "react-router-dom";

function Error() {
  const navigate = useNavigate();
  return (
    <div className="w-full h-screen dark:text-white text-gray-800  flex flex-col justify-center items-center">
      <div className="w-1/2 text-left">
        <h4 className="text-9xl font-semibold">404</h4>
        <h3 className="text-5xl">UH OH! You're lost.</h3>
        <p className="text-lg text-left">
          The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.
        </p>
        <button
          onClick={() => navigate("/")}
          className="px-6 py-2 rounded-3xl my-4 border-2 transition-all ease-linear border-amber-400 bg-transparent hover:bg-amber-400"
        >
          Home Page
        </button>
      </div>
    </div>
  );
}

export default Error;
