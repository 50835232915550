import axios from "axios";
import { Regester } from "../Layout";

const fetcher = (url) => Regester.get(url).then((res) => res.data.data);

export default fetcher;

export const fetchWithToken = (url, token) =>
  axios.get("https://atoz-register.metariom.shop" + url, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
    },
  });
