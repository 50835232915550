import React, { useContext, useEffect, useState, useMemo } from "react";
import { format } from "date-fns";
import moment from "moment";
import { useForm } from "react-hook-form";
import { contextApi } from "../Context/contextApi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { ImageUploader, Input, DatePicker, CheckoutWizard, FormNav } from "./";
import Alert from "@mui/material/Alert";
import useLoader from "../Hooks/useLoader";
import { FaArrowRight } from "react-icons/fa";
import RegesterConfigApi from "../Config/RegisterApiConfig";

function Liecence() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [selected, setSelected] = useState(null);
  const [liecence, setliecence] = useState([]);
  const { state } = useContext(contextApi);
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const { setLoader, removeLoader } = useLoader();

  let footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = <p>You picked {format(selected, "PP")}.</p>;
  }

  useEffect(() => {
    var a = moment(selected);
    var b = moment(new Date());
    var diff = a.diff(b, "days"); // 1

    if (diff < 0) {
      toast.error("Liencens is Expire");
    }
    setDateErr(false);
  }, [selected]);

  const onSubmitHandler = async (data) => {
    if (!selected) {
      setDateErr(true);
      toast.error("Liecense expire Date Must be Selected");
      return;
    }
    if (liecence.length === 0) return setError(true);
    setError(false);
    setLoader();

    const saveData = { ...data, selected, liecence };
    const allData = {
      ...data,
      expiration: moment(selected).format("YYYY-MM-DD"),
    };
    const formData = new FormData();
    Object.entries(allData).forEach(([key, value]) => formData.append(key, value));
    liecence.forEach((item) => {
      formData.append("driver_licence_image[]", item);
    });

    try {
      const { token } = state;
      const res = await RegesterConfigApi.post("/api/v1/register/driver-licence-store", formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        Cookies.set("liecence", JSON.stringify(saveData));
        const { message } = data;
        toast.success(message);
        removeLoader();
        navigate("/form/bank");
      }
    } catch (error) {
      console.log(error);
      removeLoader();
      toast.error(error?.message);
    }
  };

  const inputProps = {
    id: "driver_licence",
    label: "Driver Licence",
    type: "text",

    regester: { ...register("driver_licence", { required: "This fields is Required" }) },
    error: errors.driver_licence ? { status: true, message: errors?.driver_licence?.message } : "false",
  };
  const liecenceData = useMemo(() => (Cookies.get("liecence") ? JSON.parse(Cookies.get("liecence")) : null), []);
  useEffect(() => {
    if (liecenceData) {
      console.log(liecenceData);
      setValue("driver_licence", liecenceData.driver_licence);
      setSelected(liecenceData.selected);
      setDisable(true);
    }
  }, [liecenceData, setValue]);
  return (
    <>
      <FormNav link={"/form/truck"} title={"Liecence Information"} />
      <CheckoutWizard activeStep={3} />
      <form className="flex w-[95%] relative md:w-4/5 mx-auto flex-col gap-3" onSubmit={handleSubmit(onSubmitHandler)}>
        {/* <h3 className="text-lg md:text-xl font-semibold text-amber-400">Liecence Information</h3> */}
        <div className="flex flex-col p-2 my-3 gap-2">
          <Input {...inputProps} modal={disable} regester={inputProps.regester} />

          <ImageUploader MaxImages={2} images={liecence} setImages={setliecence} title={"Liecence Images"} />
          {error && (
            <Alert variant="filled" className="my-2" severity="error">
              Liecence image should uploaded
            </Alert>
          )}
          <DatePicker disabled={disable} title={"Date Expiration Liecence"} footer={footer} selected={selected} setSelected={setSelected} />
          {dateErr ? (
            <Alert variant="filled" className="my-2" severity="error">
              You should Select Date Expiretion
            </Alert>
          ) : null}
          <button className="self-start mt-4 flex items-center gap-2  px-4 py-2 rounded-md text-white bg-blue-500" type="submit">
            Next <FaArrowRight className="inline" />
          </button>
        </div>
      </form>
    </>
  );
}

export default Liecence;

// 'driver_licence_image' => 'required|array',
// 'driver_licence' => 'required',
// 'expiration' => 'required|date',
