import React from "react";

function CheckoutWizard({ activeStep }) {
  const stepes = [
    { name: "Personal Info", step: 1 },
    { name: "Truck Info", step: 2 },
    { name: "Licence Driver", step: 3 },
    { name: "Bank Account", step: 4 },
    { name: "Phone Verify", step: 5 },
    { name: "Sing Contract", step: 6 },
  ];

  return (
    <div className="w-screen my-4 grid grid-cols-6 items-center">
      {stepes.map((item) => (
        <div
          key={item.step}
          className={`col-span-1 border-b-4 ${
            activeStep >= item.step ? "border-b-green-500" : "border-b-gray-800 dark:border-b-gray-50  opacity-65 dark:opacity-30"
          }`}
        >
          <span
            className={`text-sm text-nowrap text-right px-2 font-semibold my-1 block sm:hidden   ${
              activeStep >= item.step ? "text-green-500" : "text-gray-400 dark:text-gray-50"
            }`}
          >
            {item.step}
          </span>
          <span
            className={`text-sm text-nowrap text-center font-semibold my-1 hidden sm:block   ${
              activeStep >= item.step ? "text-green-500" : "text-gray-400 dark:text-gray-50"
            }`}
          >
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
}

export default CheckoutWizard;
