import React, { useCallback, useContext, useEffect, useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { contextApi } from "../Context/contextApi";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { RotateLoader } from "react-spinners";
import useCodeList from "../Hooks/useCode";
import useLoader from "../Hooks/useLoader";
import moment from "moment";
import { CheckoutWizard, FormNav, Input } from "./";
import { FaArrowRight } from "react-icons/fa";
import Alert from "@mui/material/Alert";
import RegesterConfigApi from "../Config/RegisterApiConfig";

function PhoneVerify() {
  const { state } = useContext(contextApi);
  const { token } = state;
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const cookie = Cookies.get("atoz-coolies") ? JSON.parse(Cookies.get("atoz-coolies")) : null;
  const { data } = useCodeList();
  const { setLoader, removeLoader } = useLoader();
  const [isOpen, setIsOpen] = useState(false);
  // Type   phone || Backup
  const [edite, setEdite] = useState(null);
  const [code, setCode] = useState("");
  const [info, setInfo] = useState(null);
  const getRegesterPhone = useCallback(async () => {
    try {
      const res = await RegesterConfigApi.get("/api/v1/register/phone-verify", {
        headers: { Authorization: "Bearer " + cookie?.token },
      });
      if (res.status === 200) {
        setUserData([...res.data?.data]);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookie]);
  const [err, setErr] = useState(false);
  const sendSmsHandler = useCallback(
    async (obj = null) => {
      console.log(obj);
      if (obj) setInfo(obj);
      const postData = obj ? obj : info;

      try {
        setLoader();
        const res = await RegesterConfigApi.post("/api/v1/register/phone-verify-send-code", postData, {
          headers: { Authorization: "Bearer " + cookie?.token },
        });
        if (res.status === 200) {
          removeLoader();
          setCode("");
          setIsOpen(true);
          toast.success(res.data?.message);

          getRegesterPhone();
        }
      } catch (error) {
        console.error(error);
        setIsOpen(false);
        setCode("");
      }
    },
    [cookie, info, getRegesterPhone, setLoader, removeLoader]
  );
  const reSendSMSHandler = useCallback(async () => {
    console.log(info);
    setIsOpen(true);
    setErr(false);
    try {
      const res = await RegesterConfigApi.post("/api/v1/register/phone-verify-send-code", info, {
        headers: { Authorization: "Bearer " + cookie?.token },
      });
      if (res.status === 200) {
        setCode("");
        setIsOpen(true);
        toast.success(res.data?.message);
        getRegesterPhone();
      }
    } catch (error) {
      console.error(error);
      setCode("");
    }
  }, [info, cookie, getRegesterPhone]);
  const sendCodeHandler = useCallback(async () => {
    const data = {
      id: info?.id,
      code,
      type: info?.type,
    };
    console.log(data);
    // return;
    setIsOpen(false);
    try {
      setLoader();
      const res = await RegesterConfigApi.post("/api/v1/register/phone-verify-check-code", data, { headers: { Authorization: "Bearer " + token } });
      setCode("");
      console.log(res);
      if (res.status === 200) {
        removeLoader();
        toast.success(res.data?.message);
        setIsOpen(false);
        setEdite(null);
        setErr(false);
        getRegesterPhone();
      }
    } catch (error) {
      console.log(error);
      removeLoader();
      toast.error(error?.response?.data?.message);
      setErr(true);
      setCode("");
      setIsOpen(true);
    }
  }, [code, info, token, setLoader, removeLoader, getRegesterPhone]);

  useEffect(() => {
    console.log(userData, "user Data");
    if (userData) return;
    getRegesterPhone();
  }, [getRegesterPhone, userData]);
  const toggleEditeHandler = useCallback(() => {
    console.log(edite);
    edite == null ? setEdite("") : setEdite(null);
  }, [edite]);

  if (userData == null)
    return (
      <>
        <FormNav link={"/form/bank"} title={"Verify Phone Numbers"} />
        <CheckoutWizard activeStep={5} />
        <div className="flex w-[95%] relative md:w-4/5 mx-auto flex-col justify-center items-center my-6 py-20 gap-3">
          <RotateLoader color="#4ade80" size={20} speedMultiplier={0.75} margin={4} />
        </div>
      </>
    );

  return (
    <>
      <FormNav link={"/form/bank"} title={"Verify Phone Numbers"} />
      <CheckoutWizard activeStep={5} />
      <form onSubmit={(e) => e.preventDefault()} className="flex w-[95%] relative md:w-4/5 mx-auto flex-col gap-3">
        {/* <h3 className="text-lg md:text-xl font-semibold text-amber-400">Phone Numbers</h3> */}
        <div className="flex flex-col gap-2 px-2 py-2">
          {/* Table of Phone Info and Verify Date */}
          <table className="overflow-x-auto">
            <thead className="border-b dark:border-slate-50 border-slate-950">
              <tr className="py-3">
                <th className="py-2 text-left">Type</th>
                <th className="py-2 text-center">Number</th>
                <th className="py-2 text-center">Status</th>
                <th className="py-2 text-right pe-4">Date Verified</th>
              </tr>
            </thead>
            <tbody>
              {userData?.map((user, index) => {
                const { country_code, id, phone, backup_number, backup_number_verified_at, phone_verified_at } = user;
                const countryCode = data?.find((item) => item?.code === country_code);

                return (
                  <React.Fragment key={id}>
                    <tr className="border-b odd:border-gray-300 even:border-gray-400">
                      <td className="py-3 text-left">Phone</td>
                      <td className="py-3 text-center">
                        {countryCode?.dial_code}
                        {phone?.slice(1)}
                      </td>
                      <td className="py-3 text-center">
                        <button className={`px-2  rounded text-white ${phone_verified_at ? "bg-green-500" : "bg-red-500"}`}>
                          {phone_verified_at ? "Verify" : "not Verify"}
                        </button>
                      </td>
                      <td className="py-3 text-right px-4">
                        {" "}
                        {phone_verified_at ? moment(phone_verified_at).startOf("day").fromNow() : "not Verified"}
                      </td>
                    </tr>
                    <tr className="border-b odd:border-gray-300 even:border-gray-400">
                      <td className="py-3 text-left">Backup Number</td>
                      <td className="py-3 text-center">
                        {countryCode?.dial_code}
                        {backup_number?.slice(1)}
                      </td>
                      <td className="py-3 text-center">
                        <button className={`px-2  rounded text-white ${backup_number_verified_at ? "bg-green-500" : "bg-red-500"}`}>
                          {backup_number_verified_at ? "Verify" : "not Verify"}
                        </button>
                      </td>
                      <td className="py-3 text-right px-4">
                        {backup_number_verified_at ? moment(backup_number_verified_at).startOf("day").fromNow() : "not Verified"}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {/* Table of Phone Info and Verify Date */}

          {userData.map((user) => (
            <section key={user?.id} className="px-2 py-4 flex flex-col">
              {/* Phone Number  Fields*/}
              {!user?.phone_verified_at ? (
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2 items-start">
                    <h3 className="text-start">Phone Number : {user?.phone}</h3>
                    {edite == null ? null : (
                      <Input
                        id={"new Phone"}
                        label={"Edite Phone"}
                        onChange={(e) => setEdite(e.target.value)}
                        value={edite ? edite : ""}
                        type={"text"}
                      />
                    )}
                  </div>
                  <div className="flex gap-2 items-center">
                    <button
                      onClick={toggleEditeHandler}
                      className={`px-2 py-1 rounded ${
                        edite == null ? " bg-blue-500 hover:bg-blue-600" : "bg-red-500 hover:bg-red-600"
                      }  transition-all ease-linear text-white`}
                    >
                      {edite == null ? "Edite" : "Cancel"}
                    </button>
                    <button
                      onClick={() =>
                        sendSmsHandler({ id: user?.id, type: "phone", country_code: user?.country_code, phone: edite == null ? user?.phone : edite })
                      }
                      className="px-2 py-1 rounded bg-blue-500 hover:bg-blue-600 transition-all ease-in text-white"
                    >
                      Verify
                    </button>
                  </div>
                </div>
              ) : null}
              {user?.phone_verified_at && !user?.backup_number_verified_at ? (
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-2 items-start">
                    <h3 className="text-start">Backup Number : {user?.backup_number}</h3>
                    {edite == null ? null : (
                      <Input
                        id={"new Phone"}
                        label={"Edite Phone"}
                        onChange={(e) => setEdite(e.target.value)}
                        value={edite ? edite : ""}
                        type={"text"}
                      />
                    )}
                  </div>
                  <div className="flex gap-2 items-center">
                    <button
                      onClick={toggleEditeHandler}
                      className={`px-2 py-1 rounded ${
                        edite == null ? " bg-blue-500 hover:bg-blue-600" : "bg-red-500 hover:bg-red-600"
                      }  transition-all ease-linear text-white`}
                    >
                      {edite == null ? "Edite" : "Cancel"}
                    </button>
                    <button
                      onClick={() =>
                        sendSmsHandler({
                          id: user?.id,
                          type: "backup_number",
                          country_code: user?.country_code,
                          phone: edite == null ? user?.backup_number : edite,
                        })
                      }
                      className="px-2 py-1 rounded bg-blue-500 hover:bg-blue-600 transition-all ease-in text-white"
                    >
                      Verify
                    </button>
                  </div>
                </div>
              ) : null}
              {/* Phone Number  Fields*/}
              {user?.phone_verified_at && user?.backup_number_verified_at ? (
                <button
                  onClick={() => navigate("/form/contract")}
                  className="self-start mt-4 flex items-center gap-2  px-4 py-2 rounded-md text-white bg-blue-500"
                  type="submit"
                >
                  Next <FaArrowRight className="inline" />
                </button>
              ) : null}
            </section>
          ))}
        </div>
        <MyDialog
          code={code}
          setCode={setCode}
          title={"Enter Code"}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          reSend={reSendSMSHandler}
          submit={sendCodeHandler}
          err={err}
        />
      </form>
    </>
  );
}

export default PhoneVerify;

function MyDialog({ code, setCode, isOpen, setIsOpen, title, submit, reSend, type, err }) {
  const { Panel, Title } = Dialog;
  const [timer, setTimer] = useState(true);
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setTimer(false);
      return null;
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <Dialog className={"top-[25%] absolute  w-1/2 left-[25%]"} open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="fixed inset-0 z-0 bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
        <Panel className="mx-auto max-w-sm p-6 flex flex-col gap-3 rounded bg-white">
          <Title>{title}</Title>
          <MuiOtpInput value={code} autoFocus onChange={setCode} length={5} />
          {err && (
            <Alert variant="filled" className="my-2" severity="error">
              Code is incorrect
            </Alert>
          )}
          <div className="flex justify-between p-4">
            <button
              onClick={reSend}
              disabled={timer}
              className={`${
                timer ? "border-2 opacity-50 border-blue-600 text-blue-600" : " bg-blue-600   hover:bg-blue-800 text-white"
              }   px-4 transition-all ease-in flex rounded   py-2 gap-2`}
            >
              Resend
              {timer ? <Countdown date={Date.now() + 60000} renderer={renderer} /> : null}
            </button>
            <button onClick={() => submit()} className="px-2 py-1 text-white bg-blue-500 rounded">
              Validate Code
            </button>
          </div>
        </Panel>
      </div>
    </Dialog>
  );
}

//  'id' => 'required',
// 'type' => 'required|in:phone,backup_number',
// 'country_code' => 'required',
//  'phone' => 'required',
