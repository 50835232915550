import { useState, useEffect } from "react";

function useTheme() {
  const [theme, setTheme] = useState(window.matchMedia("(prefers-color-scheme: dark)").matches);
  const handleChange = (e) => {
    setTheme(e.matches);
  };
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", handleChange);
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  const [darkMode, setDarkMode] = useState(window.localStorage.getItem("theme") === "dark" ? true : false);
  document.documentElement.classList.toggle("dark", darkMode);

  useEffect(() => {
    // Check if user has a preference for dark mode in localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setDarkMode(savedTheme === "dark");
    } else {
      // If no preference, check user's system preference
      setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
    }
  }, []);

  const toggleTheme = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem("theme", newMode ? "dark" : "light");
    document.documentElement.classList.toggle("dark", newMode);
  };

  return { theme, toggleTheme, darkMode };
}

export default useTheme;
