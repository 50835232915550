import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { contextApi } from "../Context/contextApi";
import Cookies from "js-cookie";

const PrivateRoutes = () => {
  const { state } = useContext(contextApi);
  const { token } = state;
  const cookie = Cookies.get("atoz-coolies");

  // if App need token You shold uncomments below block
  if (cookie) {
    const { token } = JSON.parse(cookie);
    return token ? <Outlet /> : <Navigate to="/Auth" />;
  }
  // every time need login for user app
  return token ? <Outlet /> : <Navigate to="/Auth" />;
};

export default PrivateRoutes;
