import React from "react";
import Skeleton from "@mui/material/Skeleton";

function LoadingData({ size = 20 }) {
  const Loading = () => (
    <div className="col-span-1">
      <Skeleton width={"100%"} height={350} variant="rounded" />
    </div>
  );
  return (
    <div className="w-full  px-4 mx-5 h-screen overflow-x-hidden justify-center items-center grid gap-2 my-4 p-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  ">
      {Array(size)
        .fill("_")
        .map((i, index) => (
          <Loading key={index} />
        ))}
    </div>
  );
}

export default LoadingData;
