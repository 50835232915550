import useSwr from "swr";
import LoadConfigApi from "../Config/LoadApiConfig";

const fetcher = (url) => LoadConfigApi.get(url).then((res) => res.data);

const useUserLoad = (id) => {
  const { data, isLoading, error } = useSwr(`/api/v1/user/${id}/get-loads`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  if (id == null) {
    return { error: "id is Undefined", loadData: null };
  }
  if (error || isLoading) return { loadData: null };
  return { loadData: data?.data, error, isLoading };
};

export default useUserLoad;
