import React from "react";
import { LogoutButton } from "./";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function FormNav({ title, link }) {
  const navigate = useNavigate();

  return (
    <nav className="bg-amber-400 p-4 text-gray-950 mb-4 flex gap-2 justify-between items-center top-0 w-full">
      <h3 className="flex items-center font-semibold gap-2">
        {link && (
          <span className="cursor-pointer " onClick={() => navigate(`${link}`)}>
            <FaArrowLeft />
          </span>
        )}
        {title}
      </h3>
      <LogoutButton />
    </nav>
  );
}

export default FormNav;
