import { useState, useEffect, useCallback, useContext, useMemo, createContext } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import axiosConfig from "../Config/RegisterApiConfig";
import Cookies from "js-cookie";
import { contextApi } from "../Context/contextApi";
import useInfo from "../Hooks/useInfo";

const priodTime = 10000;
export const LocationApi = createContext();

function LocationProvider({ children }) {
  const [getLocation, setGetLocation] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const { state } = useContext(contextApi);
  const cookie = Cookies.get("atoz-coolies") ? JSON.parse(Cookies.get("atoz-coolies")) : null;
  const token = cookie ? cookie.token : state.token;
  const lastLocation = useMemo(() => (Cookies.get("last_location") ? JSON.parse(Cookies.get("last_location")) : {}), []);
  const getLocationHistory = useMemo(() => (Cookies.get("location") ? JSON.parse(Cookies.get("location")) : getLocation), [getLocation]);
  const { data } = useInfo();

  const sendLocationHandler = useCallback(
    async (lat, lng) => {
      if (!lat && !lng) return;
      if (lat >= 50 && lng >= 50) return;
      try {
        await axiosConfig.post(
          "/api/v1/user/update-location",
          {
            lat,
            lng,
            deviceId: data?.deviceId,
          },
          { headers: { Authorization: "Bearer " + token } }
        );
      } catch (error) {
        console.log(error);
      }
    },
    [token, data]
  );

  const getUserLocation = useCallback(async () => {
    // Check if geolocation is supported by the browser
    // const locationAccess=

    if (!getLocation) return;
    if (!getLocationHistory) return;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords, timestamp } = position;
          const { latitude, longitude } = coords;

          const newLocation = { latitude, longitude, timestamp: moment(timestamp).format("MMMM Do YYYY, h:mm:ss a") };
          // Set Location in Cookies
          Cookies.set("last_location", JSON.stringify(newLocation));
          setUserLocation(newLocation);
          // Check Location is Changed
          const isSame = newLocation.latitude === lastLocation?.latitude || newLocation.longitude === lastLocation?.longitude;
          // Send New Location if Changed
          if (newLocation != null && lastLocation && !isSame) sendLocationHandler(newLocation?.longitude, newLocation?.longitude);
        },

        (error) => {
          // Handle location error
          console.error("Error getting location:", error.message);
          setGetLocation(false);
          setUserLocation(false);
          toast.error(error.message);
          sendLocationHandler(null, null);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      setGetLocation(false);
      setUserLocation(false);
      console.error("Geolocation is not supportedxmxby this browser.");
      toast.error("Geolocation is not supported by this browser.");
    }
  }, [getLocation, lastLocation, sendLocationHandler, getLocationHistory]);

  const deAvtiveLocation = useCallback(async () => {
    try {
      const { status, data } = await axiosConfig.post(
        "/api/v1/user/online-offline",
        { online: "no" },
        { headers: { Authorization: "Bearer " + token } }
      );

      if (status === 200) {
        Cookies.set("location", JSON.stringify(false));
        setGetLocation(false);
        setUserLocation(null);
        toast.success(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  const activeLocation = useCallback(async () => {
    try {
      const { status, data } = await axiosConfig.post(
        "/api/v1/user/online-offline",
        { online: "yes" },
        { headers: { Authorization: "Bearer " + token } }
      );

      if (status === 200) {
        setGetLocation(true);
        Cookies.set("location", JSON.stringify(true));
        toast.success(data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [token]);

  useEffect(() => {
    if (!getLocationHistory) {
      setGetLocation(false);
      setGetLocation(false);
    }
    if (!getLocation) return;

    setTimeout(() => {
      getUserLocation();
    }, priodTime);
  }, [getUserLocation, userLocation, getLocationHistory, getLocation]);

  const value = { deAvtiveLocation, activeLocation, getLocation, userLocation };
  return <LocationApi.Provider value={value}>{children}</LocationApi.Provider>;
}

export default LocationProvider;
