import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";

import { useNavigate } from "react-router-dom";
import RegesterConfigApi from "../Config/RegisterApiConfig";

import { toast } from "react-toastify";
import { Input, OTPModal } from "../Components";

import { contextApi } from "../Context/contextApi";
import { useForm } from "react-hook-form";

import { PulseLoader } from "react-spinners";

function Register() {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  //
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  //
  const [otpModal, setOtpModal] = useState(false);
  const [code, setCode] = useState("");
  const { dispatch } = useContext(contextApi);

  const navigate = useNavigate();

  const onSubmitHandler = async (data) => {
    const { email, password, password_confirmation } = data;
    setEmail(email);
    setLoading(true);
    try {
      const { status, data } = await RegesterConfigApi.post("/api/v1/register", {
        email,
        password,
        password_confirmation,
      });
      console.log(data);
      if (status === 200) {
        setLoading(false);
        toast.success(data?.message);
        setCode("");
        setOtpModal(true);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.message);
      setLoading(false);
      if (err?.response) {
        Object.entries(err?.response?.data?.message).forEach(([key, value]) => toast.error(value[0]));
      }
    }
  };

  const Inputes = [
    {
      id: "email",
      label: "Email",
      type: "email",
      modal: otpModal,
      regester: {
        ...register("email", {
          required: "Email Field is Empty",
          pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "invalid email address" },
        }),
      },
      error: errors.email ? { status: true, message: errors?.email?.message } : "false",
    },
    {
      id: "password",
      label: "Password",
      type: "password",
      modal: otpModal,
      regester: {
        ...register("password", {
          required: "Password Field is Required",
          minLength: { value: 6, message: "Password must be at least 6 character" },
        }),
      },
      error: errors.password ? { status: true, message: errors?.password?.message } : "false",
    },
    {
      id: "password_confirmation",
      label: "Password Confirmation",
      type: "password",
      modal: otpModal,
      regester: {
        ...register("password_confirmation", {
          required: "Password Confirmation Field is Required",
          validate: (value) => value === getValues("password") || "Password and Confirmation Are NOT Same",
        }),
      },
      error: errors.password_confirmation ? { status: true, message: errors?.password_confirmation?.message } : "false",
    },
  ];
  const reSendCodeHandler = async () => {
    setCode("");
    try {
      const res = await RegesterConfigApi.post("/api/v1/register/resend-email-verify", { email });
      console.log(res);
      const { status, data } = res;
      if (status === 200) {
        toast.success(data?.message);
        setOtpModal(true);
      }
    } catch (err) {
      console.error(err.errors);
      toast.error(err?.response?.data?.message);
      console.log(err?.response);
    }
  };
  const sendCodeHandler = async () => {
    try {
      const res = await RegesterConfigApi.post("/api/v1/register/verify-email", {
        email,
        code,
      });
      const { data, status } = res;
      console.log(res);
      console.log(status, data);
      if (status === 200) {
        toast.success(data.message);
        const { token } = data;
        dispatch({ type: "Set_Data", payload: { email, token } });
        navigate("/form");
        setCode("");
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    }
  };
  return (
    <>
      <Helmet>
        <title>Registration Page</title>
      </Helmet>
      <section id="auth-form" className={`w-screen bg-cover bg-center  h-screen flex justify-center items-center`}>
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className="w-screen h-screen flex flex-col g justify-normal items-center  md:w-2/3 md:h-fit bg-black bg-opacity-35 dark:bg-slate-900 dark:bg-opacity-60 md:rounded-md 0 p-2 "
        >
          <h3 className="text-amber-400 text-2xl font-semibold text-center my-3">Atoz Drivers</h3>
          <div className="w-[95%] sm:w-3/4  flex flex-col my-4 gap-3">
            <h3 className="text-lg text-center font-semibold">Registration Form</h3>

            {Inputes.map((item) => (
              <Input key={item.id} {...item} />
            ))}

            <div className="flex flex-col-reverse gap-3 my-2 w-full">
              <h4 type="button" className="transition-all px-2 text-white ease-linear duration-100 ">
                Already have an account?{" "}
                <span onClick={() => navigate("/auth")} className="text-amber-400 font-semibold cursor-pointer">
                  Login
                </span>
              </h4>
              <button
                type="submit"
                className=" px-2 py-2 col-span-2  rounded-md bg-amber-400 hover:bg-amber-500  transition-all ease-linear duration-100 "
              >
                {!loading ? "Registeration" : <PulseLoader color="#fff" size={10} />}
              </button>
            </div>
          </div>
        </form>
      </section>
      <OTPModal
        code={code}
        setCode={setCode}
        title={"Enter code send your email."}
        isOpen={otpModal}
        setIsOpen={setOtpModal}
        reSend={reSendCodeHandler}
        submit={sendCodeHandler}
      />
    </>
  );
}

export default Register;
