import React from "react";
import { Dialog } from "@headlessui/react";

import { IoClose } from "react-icons/io5";

function ConfirmModal({ isOpen, setIsOpen, title, confirm, denine, style }) {
  const { Panel } = Dialog;

  return (
    <Dialog className={`top-[25%] absolute bg-slate-900 z-50  w-1/2 left-[25%] ${style}`} open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="fixed inset-0 z-0  bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 flex w-screen items-center justify-center p-4 ">
        <Panel className="mx-auto  flex flex-col gap-3 rounded dark:bg-gray-900 bg-slate-100   h-fit p-6  text-gray-950  w-full md:w-1/2">
          <button onClick={() => setIsOpen(false)} className="text-2xl rounded-full z-30 border w-fit absolute right-10 border-red-600 text-red-600">
            <IoClose />
          </button>

          <div className="p-6 w-full">
            <h3 className="dark:text-white text-black">{title}</h3>
            <div className="flex justify-between  py-4 items-center">
              <button onClick={confirm} className="px-4 py-2 w-1/3 rounded-md text-white bg-green-600 hover:bg-green-500 transition-all ease-linear">
                Confirm
              </button>
              <button onClick={denine} className="px-4 py-2 w-1/3 rounded-md text-white bg-red-600 hover:bg-red-500 transition-all ease-linear">
                Denine
              </button>
            </div>
          </div>
        </Panel>
      </div>
    </Dialog>
  );
}

export default ConfirmModal;
