import React, { useCallback, useState, useContext, useMemo, useEffect } from "react";
import Layout from "./Layout";
import { useParams } from "react-router-dom";
import useLoadID from "../Hooks/useLoadId";
import moment from "moment";
import { FaLocationDot, FaRegCircleDot } from "react-icons/fa6";
import Skeleton from "@mui/material/Skeleton";
import { MapComponent, ConfirmModal, BookingModal, ChatModal, Input } from "../Components";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { contextApi } from "../Context/contextApi";
import compareDates from "../Utils/CompareDates";
import RegesterConfigApi from "../Config/RegisterApiConfig";

const baseUrl = "https://atoz-loadal-location.metariom.shop/";

function SingleLoad() {
  let { id } = useParams();
  const { loadData, error, isLoading } = useLoadID(id);
  const statusStyle = loadData?.status === "accept" ? "bg-green-500" : loadData?.status === "reject" ? "bg-red-500" : "bg-amber-500";
  const [openModal, setOpenModal] = useState(false);
  const [book, setBook] = useState(false);
  const [status, setStatus] = useState(null); //null accept reject
  const { state } = useContext(contextApi);
  const token = useMemo(() => (Cookies.get("atoz-coolies") ? JSON.parse(Cookies.get("atoz-coolies"))?.token : state?.token), [state]);
  const [offer, setOffer] = useState(null);
  const [err, setErr] = useState(false);
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState("");

  const messageGetHandler = useCallback(async () => {
    try {
      const { status, data } = await axios.post(
        `https://atoz-register.metariom.shop/api/v1/user/get-offer-load/messages`,
        { load_id: id },
        {
          headers: {
            "x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // updated_at;
      if (status === 200) {
        // console.log(data);
        const messages = [...data?.data];
        console.log(messages);
        messages.sort(compareDates).reverse();
        setOffer(messages);
      }
    } catch (err) {
      console.log(err);
      setErr(true);
      toast.error(err?.response?.message);
      toast.error(err?.message);
    }
  }, [id, token]);

  const statusToggle = useCallback((arg) => {
    setOpenModal(true);
    setStatus(arg);
  }, []);
  // Accept Load

  // Reject Load
  const rejectHandler = useCallback(async () => {
    try {
      const res = await axios.post(
        `${baseUrl}api/v1/user/reject-load`,
        {
          user_id: loadData?.user_id,
          load_id: id,
        },
        {
          headers: {
            " x-api-key": "Y86n5bZYZmT5bhkfJTxxYdgJlFHDy4owrNgr2536",
          },
        }
      );

      const { status, data } = res;
      if (status === 200) {
        toast.success(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [id, loadData]);
  const handleConfirm = useCallback(() => {
    if (status != null && status === "Reject") rejectHandler();
    setOpenModal(false);
    setStatus(null);
  }, [rejectHandler, status]);
  const handleDenine = useCallback(() => {
    setOpenModal(false);
    setStatus(null);
  }, []);
  useEffect(() => {
    if (!err) {
      messageGetHandler();
    }
  }, [messageGetHandler, err]);

  const sendOfferHandler = useCallback(async () => {
    const offerData = {
      offer_price: price,
      load_id: id,
    };

    try {
      const resData = await RegesterConfigApi.post("/api/v1/user/store-offer-load", offerData, {
        headers: { Authorization: "Bearer " + state?.token },
      });
      console.log(resData);
      const { status, data } = resData;
      if (status === 200) {
        toast.success(data?.message);
        setPrice("");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setPrice("");
    }
  }, [id, price, state]);

  return (
    <Layout title={"Load Page"}>
      <section className="my-6 mb-12 scroll-smooth  p-2 relative  flex  flex-col w-screen px-4">
        {isLoading ? (
          <div className="w-full h-[300px] flex flex-col gap-4 my-2">
            <Skeleton width={"100%"} height={300} sx={{ bgcolor: "#282828" }} variant="rounded" />
            <Skeleton width={"100%"} height={300} sx={{ bgcolor: "#282828" }} variant="rounded" />
            <Skeleton width={"100%"} height={100} sx={{ bgcolor: "#282828" }} variant="rounded" />
          </div>
        ) : error == null ? (
          <>
            <div className="h-[250px] md:h-[400px]  rounded-md w-full bg-red-50">
              <MapComponent location1={loadData?.load_deliver_to_location} location2={loadData?.load_pick_up_at_location} />
            </div>
            <div className="grid my-2 grid-col-1 sm:grid-cols-2 bg-slate-200 dark:bg-slate-800 rounded-md p-2 gap-1 w-full">
              <div className="col-span-1 flex p-2 flex-col border-b dark:border-slate-50 border-slate-700 sm:border-e sm:border-b-0">
                <h3 className=" dark:text-gray-300 text-gray-800">
                  Load Date: <span className="text-sm">{moment(loadData?.load_date).format("MMM Do dddd h:mm a")}</span>
                </h3>
                <div className="flex flex-col justify-start items-start  text-sm  dark:text-gray-300 text-gray-800">
                  <span className=" flex items-center gap-1 py-1">
                    <FaLocationDot className="text-green-500" /> {loadData?.load_pick_up}
                  </span>
                  <span className="h-8 w-[2px] ms-[5px]  dark:bg-gray-300 bg-gray-800"></span>
                  <span className=" flex items-center gap-1 py-1">
                    <FaRegCircleDot className="text-red-600" /> {loadData?.load_deliver_to}
                  </span>
                </div>
                <p className="text-sm my-2 text-pretty  dark:text-gray-400 text-gray-800">{loadData?.load_subject}</p>
              </div>
              <div className="col-span-1 flex p-2 flex-col justify-start items-start gap-1">
                <h3 className="text-sm dark:text-gray-300 text-gray-800">
                  Turck type: <span className="text-base dark:text-gray-200 text-gray-900">{loadData?.user_truck_size}</span>
                </h3>
                <h3 className="text-sm dark:text-gray-300 text-gray-800">
                  Total Distance : <span className="text-base dark:text-gray-200 text-gray-900">{loadData?.load_miles} mi</span>
                </h3>
                <h3 className="text-sm dark:text-gray-300 text-gray-800">
                  Weight : <span className="text-base dark:text-gray-200 text-gray-900">{loadData?.load_weight} tonnes</span>
                </h3>
                <h3 className="text-sm dark:text-gray-300 text-gray-800">
                  Notes : <span className="text-base dark:text-gray-200 text-gray-900">{loadData?.load_notes}</span>
                </h3>
              </div>
            </div>
            <div className="my-2   flex flex-col py-4  bg-slate-200 dark:bg-slate-800 rounded-md p-2">
              <h2 className="ms-4 text-lg">
                User Status : <span className={`capitalize text-white px-2 py-1 rounded-xl ${statusStyle}`}>{loadData?.status}</span>
              </h2>
              {loadData?.status === "pending" && !offer ? (
                <div className="w-full grid-cols-2 grid py-4 gap-4 px-4">
                  <button
                    onClick={() => setBook(true)}
                    className="col-span-1 py-1 transition-all rounded-lg ease-in text-white bg-green-500 hover:bg-green-600 "
                  >
                    Send Your Offer
                  </button>
                  <button
                    onClick={() => statusToggle("Reject")}
                    className="col-span-1 py-1 transition-all rounded-lg ease-in text-white bg-red-500 hover:bg-red-600"
                  >
                    Reject
                  </button>
                </div>
              ) : (
                <div className="w-full grid-cols-2 grid py-4 gap-4 px-4">
                  <button
                    onClick={() => setOpen(!open)}
                    className="col-span-1 py-1 transition-all rounded-lg ease-in text-white bg-green-500 hover:bg-green-600 "
                  >
                    All Offer
                  </button>
                  <button
                    onClick={() => statusToggle("Reject")}
                    className="col-span-1 py-1 transition-all rounded-lg ease-in text-white bg-red-500 hover:bg-red-600"
                  >
                    Reject
                  </button>
                  {open && (
                    <div className="col-span-full flex flex-col gap-4">
                      <ChatModal load_id={id} offers={offer} />
                      <div className="w-full   grid gap-2 grid-cols-3">
                        <h3 className="col-span-full">New Price To Send.</h3>
                        <span className="col-span-2">
                          <Input id={"price"} label={"Price"} onChange={(e) => setPrice(e.target.value)} type={"text"} value={price} />
                        </span>
                        <button
                          onClick={() => sendOfferHandler()}
                          className="px-1 py-1 col-span-1 rounded-md text-white bg-green-600 hover:bg-green-500 transition-all ease-linear"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        ) : null}
      </section>
      <ConfirmModal
        isOpen={openModal}
        confirm={handleConfirm}
        denine={handleDenine}
        title={`Are You sure to ${status} load?`}
        setIsOpen={setOpenModal}
      />
      <BookingModal denine={() => setBook(false)} isOpen={book} setIsOpen={setBook} data={loadData ? loadData : {}} />
    </Layout>
  );
}

export default SingleLoad;
