import React, { useState } from "react";
import { Map, Marker, ZoomControl } from "pigeon-maps";

import { FaTruck, FaBox } from "react-icons/fa6";
import FullMap from "./FullMap";

const MapComponent = ({ location1, location2 }) => {
  let marker1 = location1.split(",").map((item) => Number.parseFloat(item));
  let marker2 = location2.split(",").map((item) => Number.parseFloat(item));
  const [openMap, setOpenMap] = useState(false);

  return (
    <div className="w-full h-full relative rounded-md  z-0">
      <Map
        onClick={() => setOpenMap(true)}
        center={marker1}
        className="rounded-md"
        zoom={12}
        animate={true}
        zoomSnap={true}
        mouseEvents={true}
        touchEvents={true}
      >
        <Marker anchor={marker1}>
          <FaTruck className="text-green-500 text-2xl" />
        </Marker>
        <Marker anchor={marker2}>
          <FaBox className="text-2xl text-red-600" />
        </Marker>
        <ZoomControl />
      </Map>
      <FullMap isOpen={openMap} marker1={marker1} marker2={marker2} setIsOpen={setOpenMap} />
    </div>
  );
};

export default MapComponent;
