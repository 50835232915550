import React, { useContext } from "react";
import { FaHome, FaTruck, FaUser, FaTruckLoading } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { LocationApi } from "../Context/locationApi";
import { FaPowerOff } from "react-icons/fa6";

function MobileMenu() {
  const { deAvtiveLocation, activeLocation, getLocation } = useContext(LocationApi);

  return (
    <nav className="w-screen  bg-zinc-300 dark:bg-amber-400  z-50 sm:hidden flex fixed  bottom-0  text-gray-900 text-base p-2 mx-auto justify-around items-center">
      <ul className="flex w-full items-center relative justify-around gap-4 text-base   ">
        <li>
          <NavLink
            className={({ isActive, isPending }) =>
              isActive
                ? "text-center flex font-semibold flex-col items-center justify-center dark:text-gray-100 text-blue-500  transition-all ease-linear  "
                : "text-center flex flex-col items-center justify-center transition-all ease-linear "
            }
            to={"/"}
          >
            <FaHome className="text-xl" />
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive, isPending }) =>
              isActive
                ? "text-center flex flex-col items-center justify-center dark:text-gray-100 text-blue-500  transition-all ease-linear  "
                : "text-center flex flex-col items-center justify-center transition-all ease-linear "
            }
            to={"/loads"}
          >
            <FaTruckLoading className="text-xl" />
            Loads
          </NavLink>
        </li>
        <li>
          <button
            onClick={getLocation ? () => deAvtiveLocation() : () => activeLocation()}
            className={`text-xl text-white flex p-2  items-center gap-1 rounded-full transition-all ease-in ${
              !getLocation ? "bg-red-500 hover:bg-red-600" : "bg-green-500 hover:bg-green-600"
            }`}
          >
            <FaPowerOff />
            <span className="text-sm">{getLocation ? "on" : "off"}</span>
          </button>
        </li>
        <li>
          <NavLink
            className={({ isActive, isPending }) =>
              isActive
                ? "text-center flex flex-col items-center justify-center dark:text-gray-100 text-blue-500  transition-all ease-linear  "
                : "text-center flex flex-col items-center justify-center transition-all ease-linear "
            }
            to={"/truck"}
          >
            <FaTruck className="text-xl" />
            Truck
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive, isPending }) =>
              isActive
                ? "text-center flex flex-col items-center justify-center dark:text-gray-100 text-blue-500  transition-all ease-linear  "
                : "text-center flex flex-col items-center justify-center transition-all ease-linear "
            }
            to={"/profile"}
          >
            <FaUser className="text-xl" />
            Profile
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default MobileMenu;
