import React, { useContext, useEffect, useState } from "react";
import { Input, PhoneCode, CheckoutWizard, FormNav } from "./index";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import RegesterConfigApi from "../Config/RegisterApiConfig";
import { toast } from "react-toastify";
import { contextApi } from "../Context/contextApi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import useLoader from "../Hooks/useLoader";
import { FaArrowRight } from "react-icons/fa";

function Info() {
  const { setLoader, removeLoader } = useLoader();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [company, setCompany] = useState(false);
  const { state } = useContext(contextApi);
  const [disable, setDisable] = useState(false);
  const onSubmit = async (data) => {
    const { first_name, last_name, country_code, phone, backup_number, company_name, citizenship_ein, mc_number, citizenship_ssn } = data;
    const BaseInfo = {
      first_name,
      last_name,
      country_code,
      phone,
      backup_number,
      company: company ? "yes" : "no",
    };
    const allData = company ? { ...BaseInfo, company_name, citizenship_ein, mc_number } : { ...BaseInfo, citizenship_ssn };
    const { token } = state;
    console.log(allData);

    setLoader();

    try {
      const { data, status } = await RegesterConfigApi.post(
        "/api/v1/register/personal-info-store",
        { ...allData },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      if (status === 200) {
        const { message } = data;
        toast.success(message);
        removeLoader();
        Cookies.set("info", JSON.stringify(allData));
        navigate("/form/truck");
      }
    } catch (err) {
      removeLoader();
      console.log(err);
      toast.error(err?.message);
      const errMessages = err?.response?.data;
      if (errMessages) {
        Object.entries(errMessages).forEach(([title, error]) => {
          toast.error(error);
        });
      }
    }
  };
  let info = Cookies.get("info") ? JSON.parse(Cookies.get("info")) : null;
  useEffect(() => {
    if (info) {
      Object.entries(info).forEach(([key, value]) => {
        setValue(key, value);
      });
      if (info.company === "yes") setCompany(true);
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [info, setValue]);
  const inputes = [
    {
      id: "phone",
      label: "Phone",
      type: "text",
      register: { ...register("phone", { required: "phone number is required" }) },
      error: errors.phone ? { status: true, message: errors?.phone?.message } : "false",
    },
    {
      id: "backup_number",
      label: "Backup Phone",
      register: { ...register("backup_number", { required: "Backup Phone number is required" }) },
      error: errors.backup_number ? { status: true, message: errors?.backup_number?.message } : "false",
    },
  ];
  const company_input = [
    {
      id: "company_name",
      label: "Company Name",
      type: "text",
      register: { ...register("company_name", { required: company ? "This Filed is Required" : false }) },
      error: company && errors?.company_name ? { status: true, message: errors?.company_name?.message } : null,
    },
    {
      id: "citizenship_ein",
      label: "Citizenship Ein",
      type: "text",
      register: { ...register("citizenship_ein", { required: company ? "This Filed is Required" : false }) },
      error: company && errors?.citizenship_ein ? { status: true, message: errors?.citizenship_ein?.message } : null,
    },
    {
      id: "mc_number",
      label: "MC Number",
      type: "text",
      register: { ...register("mc_number", { required: company ? "This Filed is Required" : false }) },
      error: company && errors?.mc_number ? { status: true, message: errors?.mc_number?.message } : null,
    },
  ];

  return (
    <>
      <FormNav title={"Driver Information"} link={null} />
      <CheckoutWizard activeStep={1} />

      <form onSubmit={handleSubmit(onSubmit)} className="flex w-[95%] relative md:w-4/5 mx-auto flex-col   gap-4">
        <div className="gap-3 w-full grid grid-cols-2">
          <span className="col-span-2 sm:col-span-1  ">
            <Input
              modal={disable}
              id={"fname"}
              label={"First Name"}
              type={"text"}
              regester={{
                ...register("first_name", {
                  required: "First name Field is required",
                  pattern: { value: /^[A-Za-z]+$/i, message: "Format is incorrect" },
                }),
              }}
              error={errors.first_name ? { status: true, message: errors?.first_name?.message } : null}
            />
          </span>
          <span className="col-span-2 sm:col-span-1  ">
            <Input
              modal={disable}
              id={"lname"}
              label={"Last Name"}
              type={"text"}
              regester={{
                ...register("last_name", {
                  required: "Last name Field is required",
                  pattern: { value: /^[A-Za-z]+$/i, message: "Format is incorrect" },
                }),
              }}
              error={errors.last_name ? { status: true, message: errors?.last_name?.message } : "false"}
            />
          </span>
        </div>
        <div className="my-2">
          <PhoneCode
            disable={disable}
            id="code"
            label="Country Code"
            type="selectBox"
            regester={{ ...register("country_code", { required: "You Should to Select Counutry Code" }) }}
            error={errors.country_code ? "true" : "false"}
          />
          {errors.countery_code?.type === "required" && (
            <Alert variant="filled" className="my-2" severity="error">
              {errors?.countery_code?.message}
            </Alert>
          )}
        </div>
        <div className="grid grid-cols-2 gap-3 my-2 w-full">
          {inputes.map((item) => (
            <span key={item.id} className="col-span-2 sm:col-span-1">
              <Input modal={disable} {...item} regester={item.register} />
              {errors === "required" && (
                <Alert variant="filled" className="my-2" severity="error">
                  {errors?.countery_code?.message}
                </Alert>
              )}
            </span>
          ))}
        </div>
        <div className="flex flex-col justify-start">
          {/* Commpany Inputes and Regester form  */}
          {!company && (
            <div className="transition-all ease-linear">
              <Input
                modal={disable}
                id={"citizenship_ssn"}
                label={"Citizenship SNN"}
                type={"text"}
                regester={{ ...register("citizenship_ssn", { required: !company ? "Citizenship Social Security Number is required" : false }) }}
                error={errors?.citizenship_ssn ? { status: true, message: errors?.citizenship_ssn?.message } : null}
              />
            </div>
          )}
          <div className="py-3 flex items-center gap-1">
            <Checkbox disabled={disable} value={company} onChange={() => setCompany(!company)} color="primary" sx={{ padding: "0.1rem" }} />
            <p>Company</p>
          </div>
        </div>
        {company && (
          <div className="grid transition-all ease-linear gap-3 w-full grid-cols-2">
            {company_input.map((item) => (
              <span className="col-span-2 sm:col-span-1 " key={item.id}>
                <Input modal={disable} {...item} regester={item.register} />
              </span>
            ))}
          </div>
        )}
        <button className="self-start mt-4 flex items-center gap-2  px-4 py-2 rounded-md text-white bg-blue-500" type="submit">
          Next <FaArrowRight className="inline" />
        </button>
      </form>
    </>
  );
}

export default Info;
