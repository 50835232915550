import React, { useContext, useState } from "react";
import { Input } from "../Components";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import { FaKey } from "react-icons/fa";
import useTheme from "../Hooks/useTheme";
import { contextApi } from "../Context/contextApi";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import RegesterConfigApi from "../Config/RegisterApiConfig";

// // Yup Schema for Validation Regester
// import { object, string } from "yup";
// const LoginSchema = object({
//   email: string().email("Email Format is incorrect").required("Email Field is Empty"),
//   password: string().required("Password Field is Empty").min(6, "Password must be at least 6 character"),
// });

function Auth() {
  const [remember, setRemember] = useState(false);
  const { dispatch } = useContext(contextApi);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    const { email, password } = data;

    setLoading(true);
    try {
      const { data, status } = await RegesterConfigApi.post("/api/v1/login", {
        email,
        password,
      });
      if (status === 200) {
        setLoading(false);
        const { token, message } = data;
        console.log(data);
        dispatch({ type: "Set_Data", payload: { email, token } });
        toast.success(message);
        navigate("/");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.message);
      toast.error(err?.response?.data?.message);
    }
  };
  const Inputes = [
    {
      id: "email",
      label: "Email",
      type: "email",
      regester: {
        ...register("email", {
          required: "Email Field is Empty",
          pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "invalid email address" },
        }),
      },
      error: errors.email ? { status: true, message: errors?.email?.message } : "false",
    },
    {
      id: "password",
      label: "Password",
      regester: {
        ...register("password", { required: "Password Field is Empty", minLength: { message: "Password must be at least 6 character", value: 6 } }),
      },
      type: "password",
      error: errors.password ? { status: true, message: errors?.password?.message } : "false",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Login Page</title>
      </Helmet>
      <section id="auth-form" className={`w-screen bg-cover bg-center  h-screen flex justify-center items-center`}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-screen h-screen flex flex-col g justify-normal items-center  md:w-2/3 md:h-fit bg-black bg-opacity-35 dark:bg-slate-900 dark:bg-opacity-60 md:rounded-md 0 p-2 "
        >
          <h3 className="text-amber-400 text-2xl font-semibold text-center my-3">Atoz Drivers</h3>
          <div className="w-[95%] sm:w-3/4  flex flex-col my-4 gap-3">
            <h3 className="text-lg text-center font-semibold">Log in Form</h3>
            {Inputes.map((input) => (
              <Input key={input.id} {...input} />
            ))}
            <div className="flex px-1 items-center flex-wrap">
              <Checkbox value={remember} onChange={() => setRemember(!remember)} color="default" sx={{ padding: "0.1rem" }} />
              <p className="cursor-pointer">Remember me</p>
            </div>
            <div className="grid grid-cols-4 gap-2 gap-y-4  w-full">
              <button
                disabled={loading}
                type="submit"
                className=" px-2 py-2 col-span-4 text-center flex items-start justify-center font-semibold rounded-md bg-amber-400 hover:bg-amber-500  transition-all ease-linear duration-100 "
              >
                {!loading ? "Login" : <PulseLoader color="#fff" size={10} />}
              </button>
              <button
                type="button"
                className=" px-2 py-2 col-span-2 flex gap-1 items-center justify-center text-center rounded-md bg-green-500 hover:bg-green-600  transition-all ease-linear duration-100 "
              >
                <FaKey />
                Forget Password
              </button>
              <button
                type="button"
                className="px-2 py-2 
                 col-span-2 rounded-md bg-gray-500 bg-opacity-50 hover:bg-opacity-40 transition-all ease-linear duration-100 "
                onClick={() => navigate("/register")}
              >
                Registeration
              </button>
            </div>
          </div>
        </form>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={theme ? "dark" : "light"}
        />
      </section>
    </>
  );
}

export default Auth;
