import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import Cookies from "js-cookie";
import useLoader from "../../Hooks/useLoader";
import { PulseLoader } from "react-spinners";

function Form() {
  const { loader } = useLoader();
  const cookie = Cookies.get("atoz-coolies") ? JSON.parse(Cookies.get("atoz-coolies")) : null;
  if (!cookie) return <Navigate to={"/Auth"} />;

  return (
    <div className="w-screen pb-5 h-[100vh] min-h-screen   bg-neutral-50 dark:bg-slate-800  overflow-y-auto relative ">
      <Outlet />
      {loader && (
        <div className="w-screen h-screen flex flex-col justify-center items-center bg-black/40 fixed top-0 left-0">
          {/* <h3 className="">Please Wait to send Data</h3> */}
          <PulseLoader color="#4ade80" size={30} speedMultiplier={0.8} margin={8} />
        </div>
      )}
    </div>
  );
}

export default Form;
